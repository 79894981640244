import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { DropdownUI, LabelUI, ItemUI, MenuChevronDown, MenuChevronUp } from "./style"
import { SessionContext } from "../../services/context"
import TrackingManager from "../../services/tracking"

const labels = {
  en: "EN", // Commonly used and first in the list
  de: "DE", // Germanic languages
  es: "ES", // Romance languages
  fr: "FR",
  it: "IT",
  pt: "PT",
  nl: "NL", // Germanic languages
  ja: "JA", // East Asian languages
  vi: "VN",
  zh: "中文",
  pl: "PL", // Other languages at the bottom
  sv: "SV",
  ar: "AR",
}

const Item = ({ language }) => {
  return (
    <ItemUI>
      <LabelUI>{labels[language]}</LabelUI>
    </ItemUI>
  )
}

const languageOptions = [
  {
    value: "en",
    content: <Item language="en" />,
  },
  {
    value: "de",
    content: <Item language="de" />,
  },
  {
    value: "es",
    content: <Item language="es" />,
  },
  {
    value: "fr",
    content: <Item language="fr" />,
  },
  {
    value: "it",
    content: <Item language="it" />,
  },
  {
    value: "pt",
    content: <Item language="pt" />,
  },
  {
    value: "nl",
    content: <Item language="nl" />,
  },
  {
    value: "ja",
    content: <Item language="ja" />,
  },
  {
    value: "vi",
    content: <Item language="vi" />,
  },
  {
    value: "zh",
    content: <Item language="zh" />,
  },
  {
    value: "pl",
    content: <Item language="pl" />,
  },
  {
    value: "sv",
    content: <Item language="sv" />,
  },
  {
    value: "ar",
    content: <Item language="ar" />,
  },
]


const selectIcons = [<MenuChevronDown />, <MenuChevronUp />]

const SelectLanguage = (props) => {
  const { i18n } = useTranslation()

  const [value, setValue] = useState(i18n.language.substring(0, 2))
  const [icon, setIcon] = useState(selectIcons[0])

  let session, sessionId, deviceId
  session = useContext(SessionContext)
  sessionId = session.sessionId
  deviceId = session.deviceId

  const changeLanguage = (selected) => {
    if (value !== selected) {
      const trackingManager = new TrackingManager()
      trackingManager.track({
        event: "Language Changed",
        data: {
          deviceId,
          sessionId,
          language: selected,
        },
      })

      i18n.changeLanguage(selected)
      setValue(selected)
    }
  }

  const handleOnOpen = () => {
    setIcon(selectIcons[1])
  }

  const handleOnClose = () => {
    setIcon(selectIcons[0])
  }

  const filterCurrentOption = (options) => {
    return options.filter(({ value: current }) => {
      return value !== current
    })
  }

  const trigger = <Item language={value} />

  return (
    <DropdownUI
      icon={icon}
      trigger={trigger}
      options={filterCurrentOption(languageOptions)}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      onChange={changeLanguage}
    />
  )
}

export default SelectLanguage
