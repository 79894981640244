const ja = {
  pageTitle: "Laavaスキャナー",
  pageDescription: "Laava SmartFingerprint™をスキャンして、製品が本物であることを確認します。",
  loading: "読み込み中",
  startingScanner: "スキャナーを起動しています",
  privacyPolicy: "Laavaを使用することにより、あなたは私たちに同意します",
  cookiesPolicy: "クッキーポリシー",
  privacyAndCookies: "プライバシーとクッキー",
  termsOfUse: "利用規約",
  accept: "承認",
  copyrightContent:
    "LAAVA、SMART FINGERPRINTS、FINGERPRINTS、AUTHENTICATION OF THINGS、Laavaチェック、Laavaロゴ、およびLAAVA ???? Laava IDPtyLtdの商標です。",
  titleWarningAllowCamera: "カメラへのアクセスを許可してください。",
  bodyWarningAllowCamera:
    "このWebスキャナーは、カメラを使用して指紋をスキャンします。カメラへのアクセスを許可して、再試行してください。",
  titleWarningSupportedBrowser: "{{browser}}を開いて指紋をスキャンしてください。",
  bodyWarningSupportedBrowser: "使用しているブラウザは現在サポートされていません。",
  activateTheCameraOn: "{{device}}でカメラをアクティブにします。",
  thisWebscannerUsesTheCamera: "このWebスキャナーは、カメラを使用して指紋をスキャンします。",
  scanNow: "今スキャンして",
  refresh: "更新",
  refreshThePage: "ページを更新",
  loadAgain: "scan.laava.idを再度ロードします。",
  selectAllowWhenAsked: "カメラへのアクセスを求められたら許可を選択",
  whenAskedSelectAllow: "scan.laava.idにアクセスしたい場合は、[許可]を選択してください。",
  positionTheCode: "スキャンするフレーム内にコードを配置します",
  seeSupportedBrowsers: "ブラウザを起動します",
  supportedBrowsersPageTitle: "このスキャナーには、カメラをサポートするブラウザーが必要です。",
  supportedBrowsersPageBody:
    "お使いのデバイスのオペレーティングシステムは、どのWebブラウザがカメラをサポートしているかを判断します。",
  howUnblockCamera: "カメラのブロックを解除する方法",
  help: "ヘルプ",
  supportedBrowsersPageHelpBody: "ご質問やご不明な点がございましたら、お問い合わせください。",
  contactUs: "お問い合わせ",
  goToBrowserSettings: "ブラウザの設定に移動します",
  findSettingBrowser: "ブラウザの右上隅にある3つのドットをタップすると、設定を見つけることができます。",
  selectSiteSettings: "サイト設定を選択",
  foundAdvanced: "これは、advancedの小見出しの下にあります。",
  selectCamera: "カメラを選択",
  selectWebsite: "「https://scan.laava.id」を選択します",
  changePermission: "許可するには、カメラへのアクセス許可を変更してください。",
  returnToWebscanner: "Webスキャナーに戻ってスキャンします。",
  lookForLaava: "Laavaを探してください。",
  simplyScan: "単にスキャンします。",
  shopWithAssurance: "保証付きで買い物。",
  laavaEnablesBrands:
    "Laavaを使用すると、ブランドはCSIROで開発された新しい安全なテクノロジーを使用して、製品の品質に関する保証を提供できます。",
  aboutLaava: "Laavaについて",
  knowYouReGetting: "あなたが本物を手に入れていることを知っていて、代用品ではありません",
  laavaIsANewKind:
    "Laavaは新しい種類のバーコードであり、料金を支払っている製品が本物であることを確認し、品質の低い模倣から安全に保つことができます。",
  everyFingerprint: "すべての指紋は完全に一意であり、他の指紋とは異なります",
  aFingerprintDoes:
    "指紋は、保持している製品またはバッチを示すだけではありません。それはあなたの手にある正確な製品のための個々の、完全にユニークな指紋です。",
  seeBeyondTheLabel: "ラベルの先を見て、あなたの製品についてもっと知る",
  scanningAllowsYou:
    "スキャンすると、ラベルの内容だけでなく、より多くの情報を確認できます。製造場所や梱包時期など、その正確なアイテムに関する具体的な情報を確認してください。",
  ourCompany: "当社",
  laavaWasFounded:
    "Laavaは2016年にオーストラリアで設立されました。コンセプトとテクノロジーのソリューションは、光学、ブロックチェーン、マーケティング、消費者インサイトの分野で世界をリードする専門家によって開発されました。",
  needHelpScanning: "スキャンのヘルプが必要ですか？",
  youCanFindDetails: "スキャンの詳細と一般的な問題の回答については、ヘルプガイドをご覧ください。",
  getHelp: "スキャンのヘルプを取得する",
  howScan: "スキャンする方法",
  problemsWithTheFingerprint: "指紋の問題",
  commonProblems: "一般的な問題",
  getResults:
    "最良の結果を得るには、これらのヒントは、スキャンの品質に影響を与える可能性のあるいくつかの一般的な問題を克服するのに役立ちます。",
  readyToScan: "指紋をスキャンする準備はできましたか？",
  needMoreHelp: "もっと助けが必要ですか？",
  scanFingerprint: "指紋をスキャンする",
  bringFingerprint:
    "ビュー内で指紋に焦点を合わせ、指紋が検出されるまで所定の位置に保持します。接続速度によっては、少し時間がかかる場合があります。",
  locateSmartFingerprint: "SmartFingerprint™を見つけます",
  fingerprintsCanBeFound: "指紋は、さまざまな日用品に見られます。",
  alignTheFingerprint: "ガイドを使用して指紋を揃えます",
  alignTheGuide: "ガイドを指紋の内側と外側のリングに合わせます。",
  snapPhoto: "写真を撮る",
  getYourFingerprint: "指紋に焦点を合わせ、手を安定させます。",
  light: "光",
  likeAnyCamera:
    "他のカメラと同様に、最良の結果は明るい場所にあります。指紋が十分に照らされていることを確認し、まぶしさを監視します。",
  keepYourHands: "手を安定させてください",
  tryKeepYourHand: "写真を撮るときは、手とカメラを動かさないようにしてください。そうすれば、画像が鮮明になります。",
  considerTheAngle: "角度を考慮してください",
  scanYourFingerprint:
    "カメラの正面にある位置で指紋をスキャンし、（焦点を合わせたままで）ビューの大部分を塗りつぶします。",
  watchOutForShadow: "影に気をつけろ",
  whenScanning: "スキャンするときは、電話が指紋に暗い影を落としていないことを確認してください。",
  haveAnyQuestion: "質問やフィードバックがありますか？あなたからの御一報をお待ちしています。",
  email: "Eメール",
  startingCamera: "カメラの起動",
  searching: "走査",
  analysing: "分析",
  sending: "送信",
  sendingForVerification: "確認のために送信",
  mightTakeAFewMoments: "少し時間がかかる場合があります",
  placeCodeWithinFrame: "スキャンするフレーム内にコードを配置します",
  needHelp: "助けが必要？",
  googlePrivacyPolicy: "Googleプライバシーポリシー",
  laavaPrivacy: "Laavaプライバシー",
  laavaHasAPrivacy:
    "Laavaには{{manifesto }}があり、LaavaFingerprintsのユーザーとしてのあなたとあなたのプライバシーに影響を与えるポリシーを説明しています。これとは別に、個人情報を収集する必要がある限られた状況でのみ適用される{{policy }}もあります。",
  privacyManifesto: "プライバシーマニフェスト",
  corporatePrivacy: "企業のプライバシーポリシー",
  laavaWebscannerTerm: "Laava - Webscannerの利用規約",
  general: "全般的",
  websiteUse: "ウェブサイトの使用",
  intellectualProperty: "知的財産",
  limitionOfLiability: "責任の制限",
  indemnity: "補償",
  otherWebsites: "他のウェブサイトおよびサードパーティ",
  entireAgreement: "完全合意",
  invalidity: "無効性と強制力",
  privacy: "プライバシー",
  applicableLaw: "適用法",
  howToContact: "お問い合わせ方法",
  termsP00:
    "Laava Webサイト（Webサイト）にアクセスしていただきありがとうございます。このウェブサイトは、Laava ID Pty Ltd（ABN 99 617 775 578）（Laava）が所有および運営しています。",
  termsP01:
    "ウェブサイトの使用は、これらの利用規約に準拠します。ウェブサイトにアクセスして使用することにより、これらの利用規約に拘束されることに同意したことになります。",
  termsP02:
    "Laavaは、これらの利用規約の一部または全部をいつでも変更することができます。最新の利用規約は常にウェブサイトに掲載されます。その後または継続してウェブサイトを使用することにより、これらの利用規約に加えられた変更に同意したものと見なされます。これらの利用規約またはそれらに加えられた変更に異議を唱える場合は、ウェブサイトの使用を中止する必要があります。",
  termsP03: "これらの条件の最終更新日は2018年11月26日です。",
  termsP1: "ウェブサイトまたはコンテンツは、非営利目的でのみ使用できます。",
  termsP2: "ウェブサイトに含まれる情報は、一般的な情報提供のみを目的としています。",
  termsP3:
    "ウェブサイトおよびウェブサイトに含まれる情報、リンク、ドキュメント、または関連するグラフィック（まとめてコンテンツ）は、「現状有姿」で提供されます。コンテンツへの依存は、厳密に自己責任で行ってください。コンテンツが意図した用途に適しているかどうかを判断するには、独自に問い合わせる必要があります。",
  termsP4:
    "Laavaは、ウェブサイトが常に利用可能で完全に機能するようにあらゆる合理的な努力を払っていますが、システム障害、メンテナンス、またはLaavaの制御が及ばない理由により、通知なしにウェブサイトへのアクセスが停止される場合があります。",
  termsP5: "Laavaは、一時的または永続的に、以下を変更または中止する場合があります。",
  termsP6: "ウェブサイト、またはウェブサイト上のコンテンツ、機能、またはサービス。また",
  termsP7: "リンクのパスまたは場所、",
  termsP8:
    "いつでも予告なしに。 Laavaは、これらの、または同様の、Webサイト、またはWebサイトの一部の変更、中止、中断に関連する責任を負わないものとします。",
  termsP9:
    "Laavaは、お客様による本利用規約の違反、適用法の違反、または疑わしい活動に応じて、警告または通知なしにWebサイトへのアクセスを終了し、それ以上のアクセスを防止する権利を留保します。",
  termsP10: "ウェブサイトを使用するとき、あなたは以下に同意しないことに同意します：",
  termsP11: "虚偽の、誤解を招く、または欺瞞的な情報を提供する",
  termsP12: "法律または規制に違反する、または違反する可能性のある方法でWebサイトを使用する",
  termsP13: "Laavaが法律または規制に違反する可能性のある方法でWebサイトを使用する",
  termsP14: "Laavaまたはその他の第三者に不利に反映する方法でウェブサイトを使用する",
  termsP15: "当社が明示的に許可した以外の目的で、ウェブサイトの他のユーザーに関する情報を収集する",
  termsP16: "インフラストラクチャに不当な負担を課すような行動を取る。",
  termsP17:
    "Webサイトを混乱または無効にする可能性のあるコンピュータウイルスまたは同様のプログラムまたはコードを配布する",
  termsP18:
    "デバイス、ソフトウェアまたはプロセス、またはその他の自動化された手段を使用して、目的を問わず、Webサイトまたはコンテンツを取得、スクレイプ、コピー、またはインデックス付けする。また",
  termsP19:
    "不動産情報のデータベースを作成する目的で、Webサイトまたはコンテンツを取得、スクレイプ、コピー、またはインデックス付けします。",
  termsP20:
    "ウェブサイトおよびウェブサイト上のコンテンツ内のすべての知的財産（テキスト、グラフィック、写真、ロゴ、または商標を含むがこれらに限定されない）は、特に明記されていない限り、Laavaが所有するか、Laavaにライセンス供与されます。",
  termsP21:
    "ウェブサイト上のコンテンツを表示するために必要な場合、法律で許可されている場合、またはLaavaの書面による明示的な許可を得ている場合を除き、複製、再コンパイル、逆コンパイル、逆アセンブル、リバースエンジニアリング、派生物の作成、実行、変更、適合を行うことはできません。 、公開、保存、使用、電子的またはその他の方法での送信、配布、またはその他の方法で、ウェブサイトの全体または一部、または個人的な非営利目的以外の目的でのウェブサイト上のコンテンツの悪用。",
  termsP22: "ウェブサイトの使用は、ご自身の責任で行ってください。",
  termsP23:
    "Laavaは、法律で認められる最大限の範囲で、明示または黙示を問わず、以下を含むがこれらに限定されないすべての表明、保証、または保証を否認します。",
  termsP24: "ウェブサイトまたはコンテンツに関する完全性、正確性、信頼性、適合性または可用性",
  termsP25: "ウェブサイトとコンテンツにエラーがないこと、または欠陥が修正されること",
  termsP26: "ウェブサイトへのアクセスが中断されない、安全である、またはエラーがないこと",
  termsP27: "ウェブサイトにウイルスやその他の有害なコンポーネントが含まれていないこと。と",
  termsP28: "リンク先のウェブサイトまたはサードパーティの資料の品質、正確性、または目的への適合性。",
  termsP29:
    "法律で認められる最大限の範囲で、Laavaとその取締役、従業員、および代理人は、契約、過失を含む不法行為、法令、またはその他の方法で発生した不法行為にかかわらず、いかなる種類の損失または損害（結果として生じる損失を含む）についても責任を負いません。ウェブサイトまたはコンテンツに関連して。",
  termsP30:
    "これらの利用規約のいかなる条項も、合意によって除外、制限、または変更されない法律（オーストラリア、オーストラリア消費者法を含む）に基づいてお客様が持つ可能性のある権利を除外、制限、または変更することを意図したものではありません。これらの利用規約がオーストラリア消費者法に準拠している場合、サービスに関する消費者保証を遵守しなかった場合のお客様に対する当社の責任は、サービスの再供給またはそのような再供給の支払いに限定されます。",
  termsP31:
    "は、これらの利用規約への違反の結果としてLaavaが被った損失、責任または損害、費用（合理的な法的および会計上の料金を含むがこれに限定されない）についてLaavaが被った責任に関してLaavaを補償します。またはウェブサイトの使用。",
  termsP32:
    "ウェブサイトには、第三者のウェブサイトへのリンク、広告、またはそれらのウェブサイト上の情報が含まれている場合があります。",
  termsP33:
    "Laavaは、サードパーティのWebサイトの性質、コンテンツ、および可用性を管理することはできません。他のウェブサイトへのリンクを含めることは、必ずしもそれらのウェブサイトまたはそれらの中で表現された見解のスポンサー、承認、または承認を意味するものではありません。",
  termsP34:
    "Laavaは、これらのWebサイトのコンテンツについて責任を負いません。サードパーティのWebサイトおよび情報の使用は、ご自身の責任で行ってください。",
  termsP35:
    "これらの利用規約は、ウェブサイトの使用とアクセス、およびウェブサイト上のコンテンツの使用とアクセスに関する、ユーザーとLaavaの間の完全な合意を表しています。オーストラリア連邦またはオーストラリアの州または準地域の法律によって含まれる必要がある場合を除き、これらの利用規約に他の用語を含めることはできません。法令によって暗示され、明示的に除外できないものを除くすべての黙示の用語は、明示的に除外されます。",
  termsP36:
    "これらの利用規約のいずれかの条項がいずれかの法域の法律の下で無効である場合、その条項は、分離可能な条件であるかどうかにかかわらず、無効でない限り、その法域で執行可能です。",
  termsP37: "Cth ）および{{policy}}に従って取り扱われます。",
  termsP38:
    "Laava指紋をスキャンするときにLaavaが個人情報を処理する方法の詳細については、{{manifesto}}を参照してください。",
  termsP39:
    "Laavaは、理由の如何を問わず、いつでも随時、Webサイトスキャナーまたはその一部を一時的または恒久的に変更、中止する権利を留保します。 Laavaは、Webサイトスキャナーまたはその一部の中止または中断の結果として、いかなる責任も負わないものとします。",
  termsP40: "次の方法でLaavaに連絡できます。",
  contact: "コンタクト",
  privacyOfficer: "プライバシーオフィサー",
  address: "住所",
  phone: "電話",
  laavaPrivacyManifesto: "Laavaプライバシーマニフェスト",
  privacyManifestoIntroduction: "Laava –プライバシーマニフェスト",
  ourApproach: "あなたのプライバシーへの私たちのアプローチ",
  whatInformationScan: "Laava指紋をスキャンするとどのような情報が収集されますか？",
  whatInformationDetect: "疑わしい活動を検出すると、どのような情報が収集されますか？",
  howDoesLaava: "LaavaはCookieをどのように使用しますか？",
  sharingInformation: "情報の共有",
  privacyManifestoP1:
    "このドキュメントは、ブラウザまたはWeChatLaavaスキャナーを使用してLaavaSmart Fingerprintをスキャンする際の情報の収集に関するポリシーの概要を示す、プライバシーマニフェストです。",
  privacyManifestoP2:
    "別の{{link }}があり、他のビジネス環境での個人情報の取り扱い方法を示しています。たとえば、あなたが私たちにポジションを申請する場合、潜在的な投資家、パートナー、顧客、サプライヤーの代表者、または私たちのサービスプロバイダー、またはあなたが私たちのヘルプデスクに連絡するか、私たちに質問や質問を提出する場合。",
  privacyManifestoP3:
    "Laava Smart Fingerprintsをスキャンする際に、個人情報を収集することは望ましくありません。あなたのプライバシーを尊重することは私たちの重要な関心事です。そのため、名前、ログイン、電話番号、電子メール、またはあなたを個人的に特定するその他の情報は必要ありません。",
  privacyManifestoP4:
    "Laava Smart Fingerprintsのスキャンは、私たちが行うすべての核心です。私たちは、Laava Smart Fingerprintをスキャンするすべての人に、安全でプライベートで心配のない環境を提供したいと考えています。 Laava Smart Fingerprintsをスキャンすることは、面倒なことや詮索好きな目がないことを意味します。",
  privacyManifestoP5:
    "私たちは、非常に多くの企業が消費者のプライバシーに関してより良い成果を上げることができると考えており、私たちはその先導を望んでいます。データを収集できるからといって、そうすべきだという意味ではありません。",
  privacyManifestoP6:
    "Laava Smart Fingerprintをスキャンすると、Laava Smart Fingerprintを認証し、そのステータスレコードを更新するために必要な、スキャンの写真とその他のスキャン情報、またはメタデータがキャプチャされます。そのメタデータには、スキャンが開始されたURL、スキャンの場所（通常、IPアドレスまたはGPSの場所から取得）、および各スキャンの時刻が含まれる場合があります。また、スキャンごとに匿名のスキャナーIDを割り当てて記録し、ブラウザーやカメラの設定などの限られたデバイス情報を使用する場合があります。",
  privacyManifestoP7:
    "重要なのは、Laava Smart Fingerprintをスキャンするときに収集するすべての情報は匿名化されており、あなたを識別するために簡単に使用することはできません（匿名化された情報） 。スキャンの写真にあなたを特定するために使用できる情報が含まれている場合、私たちはそうしようとはしません。",
  privacyManifestoP8:
    "ミニプログラムでLaavaSmartFingerprintをスキャンすると、WeChatのプライバシーポリシーも適用されます。",
  privacyManifestoP9:
    "Laavaが偽造や詐欺に対する効果的なツールであることを確認し、スキャン環境を安全に保ち、高品質のサービス、パフォーマンス、セキュリティを維持するために、さまざまな方法を使用して、個々のLaavaスマート指紋のスキャンアクティビティが疑わしい。疑わしい活動を検出した場合、スキャン活動とメタデータの徹底的な分析を実施し、この情報をお客様とパートナー（お客様と一緒に）に渡す場合があります。",
  privacyManifestoP10:
    "Laavaは、そのWebサイトでブラウザのCookieを使用しています。ブラウザのCookieは、Webサイトがブラウジングについて覚えておくのに役立つ、デバイスに保存される小さなデータです。クッキーは、当社のWebサイトのどの部分が最も人気があるかを確認するのに役立ち、お客様のエクスペリエンスを向上させることができます。",
  privacyManifestoP11:
    "当社のウェブサイトを改善するために、特定の情報が統計情報として収集される場合があり、IPアドレス、ブラウザの種類、言語、アクセス時間などが含まれる場合があります。当社のウェブサイトで使用されているツールは、あなたを個人として識別しない方法で情報を記録します。お使いのブラウザまたはデバイスでは、Cookieをブロック、使用を制限、または削除できる場合があります。",
  privacyManifestoP12:
    "ブラウザベースのスキャナーを使用してLaavaSmartFingerprintsをスキャンする場合、Cookieが使用される場合がありますが、スキャン履歴は保存されません。",
  privacyManifestoP13:
    "当社のウェブサイトは、GoogleAnalyticsやオンライン調査ツールなどのサードパーティのウェブサイトツールを使用する場合もあります。 Googleがデータを収集および処理する方法の詳細については、 {{link }}にあるGoogleのプライバシーポリシーとその情報をご覧ください。",
  privacyManifestoP14:
    "顧客は明らかに、アイテムに関連付けられたLaavaSmartFingerprintsスキャンレコードの分析に関心を持っています。 Laava Smart Fingerprintsに関するスキャン情報は、通常は集約された形式でお客様と共有されます。お客様と情報を共有する場合、Laava Smart Fingerprintがスキャンされた場所、時間、回数など、お客様が知っておく必要のあることだけを伝えます。私たちはあなたの個人情報を収集しないので、それを彼らと共有することはできません。",
  privacyManifestoP15:
    "また、Laava Smart Fingerprintをスキャンするときに、お客様が個人的にあなたと交流することもできます。この契約の一環として、お客様はお客様に個人情報を要求する場合があります。それは素晴らしいことだと思いますが、Laavaでは、個人情報の共有は常に透過的かつオプトインベースで行われます。あなたは常に責任を負い、共有するものを選択することができます。",
  privacyManifestoP16:
    "Laavaはお客様の個人情報を表示または収集しません。代わりに、お客様が共有する個人情報は、お客様のプライバシーポリシーに従って収集および処理され、お客様はこれを受け入れるよう求められます。",
  privacyManifestoP17:
    "Laavaは、裁判所の手続きを容易にしたり、Laava、このWebサイトのユーザーなどの権利、財産、または安全を保護するために、リリースが法律の遵守に適切であると判断した場合に、お客様が提供する情報を共有する場合があります。",
  laavaPrivacyPolicy: "Laavaプライバシーポリシー",
  privacyPolicyT1: "この企業プライバシーポリシーについて",
  privacyPolicyT2: "私たちはいつあなたの個人情報を収集しますか？",
  privacyPolicyT3: "私たちはどのような個人情報を収集して処理しますか？",
  privacyPolicyT4: "なぜ私たちはあなたの個人情報を収集するのですか？",
  privacyPolicyT5: "あなたの個人情報を誰に開示する可能性がありますか？",
  privacyPolicyT6: "お客様の個人情報を収集できない場合はどうなりますか？",
  privacyPolicyT7: "当社のウェブサイトおよびCookieへの訪問者",
  privacyPolicyT8: "私たちはあなたの個人情報をどのように保護しますか？",
  privacyPolicyT9: "ダイレクトマーケティングコミュニケーション",
  privacyPolicyT10: "どうすれば個人情報にアクセスして修正できますか？",
  privacyPolicyT11: "どうすればプライバシーに関する苦情を申し立てることができますか？",
  privacyPolicyT12: "お問い合わせ方法",
  privacyPolicyP1:
    "私たちは、非常に多くの企業が消費者のプライバシーに関してより良い成果を上げることができると考えており、私たちはその先導を望んでいます。データを収集できるからといって、そうすべきだという意味ではありません。私たちはあなたのプライバシーを尊重し、尊重し、あなたの個人情報の保護を真剣に受け止めます。",
  privacyPolicyP2:
    "この企業プライバシーポリシーは、Laava指紋をスキャンしたときにあなたやあなたの個人情報に何が起こるかについてではありません。 Laava Fingerprintsをスキャンするときに個人情報を収集しないというポリシーは、別の{{link }}で説明されています。",
  privacyPolicyP3:
    "この企業プライバシーポリシーは、Laava ID Pty Ltd（ABN 99617 775 578）（Laava、私たち、私たち、私たち）が、個人情報を収集する必要がある限られた状況で、あなたが私たちと一緒に仕事をするか、Laavaに投資するか、（パートナー、顧客、サプライヤー、またはサービスプロバイダーの代表として）一緒に仕事をするか、ヘルプデスクに連絡するか質問を送信するか。",
  privacyPolicyP4:
    "それは、私たちがあなたについて収集する個人情報、それをいつどのように使用するかについて概説しています。また、個人としてのプライバシーの権利と、プライバシーに関する懸念がある場合の連絡方法についても概説します。このプライバシーポリシーは随時変更される可能性があるため、定期的に確認することをお勧めします。",
  privacyPolicyP5:
    "プライバシーマニフェストに記載されているように、Laava指紋のスキャンに関連して個人情報を収集することはありません。",
  privacyPolicyP6:
    "また、Laava Fingerprints（Brands）を使用しているブランドの所有者と個人情報を共有することを選択した場合、お客様の個人情報を表示または収集することはありません。これは、プライバシーマニフェストにも記載されています。",
  privacyPolicyP7: "ただし、次の場合を含め、お客様から直接お客様の個人情報を収集する必要がある限られた状況があります。",
  privacyPolicyP8: "電話、電子メール、オンライン（当社のウェブサイトまたはヘルプデスクを含む）、または直接の連絡",
  privacyPolicyP9: "あなたが私たちと一緒にポジションを申請するとき",
  privacyPolicyP10: "あなたが投資家になることを申請するとき;また",
  privacyPolicyP11: "サプライヤー、またはサービスプロバイダーとして当社とやり取りする場合。",
  privacyPolicyP12:
    "個人情報とは、特定された個人、または合理的に特定可能な個人としてのあなたに関する情報または意見（真実であるかどうか、および重要な形式で記録されているかどうかにかかわらず）です。当社が収集および処理する個人情報の種類には、次のものが含まれます。",
  privacyPolicyP13: "名前、住所、電話番号、メールアドレス、IPアドレスなどの連絡先情報",
  privacyPolicyP14: "サプライヤー、またはサービスプロバイダーである場合、あなたの組織内でのあなたの役割に関する情報",
  privacyPolicyP15: "投資家になるために申請する場合、あなたが私たちと共有する財務およびその他の関連する詳細",
  privacyPolicyP16:
    "あなたが私たちと一緒にポジションに応募する場合、私たちはあなたの資格、スキル経験、性格、スクリーニングチェックおよび雇用履歴に関する情報を収集することがあります。と",
  privacyPolicyP17:
    "あなたが私たちのヘルプデスクを通して（またはあなたが私たちに送るどんな質問でも）あなたが私たちに提供する他の情報。",
  privacyPolicyP18:
    "当社は、主に質問への回答、当社でのポジションの申請を検討するため、またはお客様を当社の投資家、顧客、サプライヤー、またはパートナーと見なすために、個人情報を収集、保持、使用、および開示します。また、以下を含む関連する理由でお客様の個人情報を収集する場合があります。",
  privacyPolicyP19: "記録管理や内部報告などの管理目的",
  privacyPolicyP20: "あなたと通信する",
  privacyPolicyP21: "マーケティング目的;と",
  privacyPolicyP22: "適用される法律および規制を遵守するため。",
  privacyPolicyP23:
    "当社がお客様の個人情報を収集した上記のセクション2に記載されている限られた状況では、お客様の個人情報を第三者に開示する場合があります。当社が個人情報を交換する第三者の種類は次のとおりです。",
  privacyPolicyP24:
    "会計、監査、法務、銀行、支払い、配信、データホスティング、調査、採用、保険、老朽化、給与、トレーニング、テクノロジーサービスを含む当社のサービスプロバイダー",
  privacyPolicyP25:
    "私たちと一緒に仕事をすることを申し込む人々のために、審判とスクリーニングチェックプロバイダー（例えば、背景、仕事への適格性、適性と犯罪歴のチェックのために）;と",
  privacyPolicyP26: "法執行機関およびその他の組織に対して、法律で義務付けられている、または許可されている場合。",
  privacyPolicyP27:
    "海外の受信者に個人情報を開示することはありません。ただし、Googleなどの安全なデータホスティングプロバイダーにお客様の個人情報を保存する場合があります。Googleなどのプロバイダーは、その情報を次の場所に保存する場合があります：（{{link}}）。可能な場合は、お客様の個人情報をオーストラリアに保管しておく必要があります。海外の受信者に個人情報を開示する必要がある場合は、その居住国を指定して、このプライバシーポリシーを更新します。",
  privacyPolicyP28: "必要なときに個人情報をご提供いただけない場合、以下のことができない場合があります。",
  privacyPolicyP29: "従業員として私たちに参加するためのあなたのアプリケーションを検討してください",
  privacyPolicyP30:
    "アプリケーションが投資家、顧客、パートナーになることを検討してください。サプライヤーまたはサービスプロバイダー。と",
  privacyPolicyP31: "質問や情報の要求に応答します。",
  privacyPolicyP32:
    "ブラウザのCookieは、Webサイトやモバイルアプリケーションがあなたのことを思い出すのに役立つようにデバイスに保存される小さなデータです。 LaavaモバイルアプリではCookieを使用していませんが、WebサイトではCookieを使用しています。クッキーは、当社のWebサイトのどの部分が最も人気があるかを確認するのに役立ちます。これにより、お客様のエクスペリエンスを向上させるWebサイトの側面の改善に集中し、より優れた、より直感的で満足のいくエクスペリエンスを提供できます。お使いのブラウザまたはデバイスでは、Cookieの使用をブロックまたは制限できる場合があります。また、ブラウザからCookieを削除できる場合もあります。",
  privacyPolicyP33:
    "当社のウェブサイトを通じて提供するサービスを改善するために、特定の情報がブラウザから収集される場合があります。この情報は統計情報として収集され、IPアドレス、ブラウザの種類、言語、アクセス時間が含まれます。多くの場合、当社のWebサイトで使用されているツールは、お客様を個人として識別しない方法で情報を記録します。その他の場合、情報は個人情報である可能性があり、その場合、このプライバシーポリシーが適用されます。",
  privacyPolicyP34:
    "当社のウェブサイトは、GoogleAnalyticsやオンライン調査ツールなどのサードパーティのウェブサイトツールを使用する場合もあります。 Googleがデータを収集および処理する方法の詳細については、Googleのプライバシーポリシーとその情報を参照してください：（ {{link }}）。",
  privacyPolicyP35:
    "当社は、個人情報を紛失、干渉、誤用、不正アクセス、変更、開示から保護するためにあらゆる合理的な措置を講じます。これらの手順には、アクセス制御、暗号化、および安全な施設が含まれる場合があります。個人情報は、データホスティングプロバイダーなどのサードパーティの支援を受けて、物理的形式と電子的形式の両方で保存されます。",
  privacyPolicyP36:
    "時折、お客様が当社からのマーケティング情報の受信を選択した場合、当社のサービスに関するダイレクトマーケティングコミュニケーションを送信する場合があります。",
  privacyPolicyP37:
    "その後オプトアウトするか、法的な制限を受ける場合を除き、電子メッセージ（Eメールなど）、オンライン（Webサイトなど）、メール、その他の手段でお客様に連絡する場合があります。以下の連絡先の詳細を使用して当社に連絡することにより、マーケティングコミュニケーションをオプトアウトすることができます。",
  privacyPolicyP38:
    "が保有・使用する個人情報の正確性は当社にとって重要です。当社は、取り扱う個人情報が正確、完全、最新であることを保証するために合理的な措置を講じます。お客様の個人情報を正確に保つために、お客様の個人情報に誤りや変更があった場合はお知らせください。",
  privacyPolicyP39:
    "このプライバシーポリシーの下部にある連絡先の詳細を介してプライバシーオフィサーに連絡することにより、いつでも私たちが保持しているあなたの個人情報へのアクセスと修正を要求することができます。ほとんどの場合、これらのリクエストについて迅速かつ非公式にお手伝いします。それ以外の場合は、本人確認を行い、書面でのリクエストをお願いする場合があります。",
  privacyPolicyP40:
    "当社がお客様の個人情報をどのように取り扱うかについて懸念がある場合は、このプライバシーポリシーの下部にある連絡先の詳細を使用して当社のプライバシーオフィサーに連絡することができます。誰があなたの問題を処理するのか、そしていつあなたがさらなる応答を期待できるのかをあなたに知らせるために私たちは応答します。私たちはあなたの懸念に関してあなたに追加の詳細を要求するかもしれません、そして私たちはあなたの問題を調査して対処するために他の当事者と関わるか相談する必要があるかもしれません。私たちはあなたの要求と解決策の記録を保持します。苦情への対応に満足できない場合は、オーストラリア情報委員会事務局にご連絡ください。",
  privacyPolicyP41:
    "ウェブサイトスキャナーまたはこれらの利用規約に関して質問、苦情、またはクレームがある場合は、Laavaに連絡することができます。",
  problemsTitle: "指紋の問題",
  problemsIntro:
    "Laava SmartFingerprint™が破損しているように見える場合、またはスキャンに問題がある場合は、ご連絡いただければ、問題の解決に最善を尽くします。",
  tryScanningAgain: "もう一度スキャンしてみてください",
  emailUs: "私達に電子メールを送り",
  troubleDetectingFingerprint: "指紋の検出に問題があります",
  makeSureFingerprint: "指紋が十分に明るく、焦点が合っていることを確認してください",
  ensureInternet: "インターネット接続が良好であることを確認してください",
  tryAgain: "再試行",
  logIn: "ログイン",
  account: "アカウント",
  emailAddress: "メールアドレス",
  password: "パスワード",
  submit: "送信",
  thereWasAProblemLoggingIn: "ログインに問題が発生しました。もう一度お試しください。",
  loggedInAs: "ログイン済み：",
  logOut: "ログアウト"
}

export default ja
