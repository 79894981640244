import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import EN from "./locales/en.js"
import ZH from "./locales/zh.js"
import VI from "./locales/vi.js"
import PT from "./locales/pt.js"
import FR from "./locales/fr.js"
import IT from "./locales/it.js"
import DE from "./locales/de.js"
import ES from "./locales/es.js"
import JA from "./locales/ja.js"
import PL from "./locales/pl.js"
import SV from "./locales/sv.js"
import NL from "./locales/nl.js"
import AR from "./locales/ar.js"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: EN },
      zh: { translation: ZH },
      vi: { translation: VI },
      pt: { translation: PT },
      fr: { translation: FR },
      it: { translation: IT },
      de: { translation: DE },
      es: { translation: ES },
      ja: { translation: JA },
      pl: { translation: PL },
      sv: { translation: SV },
      nl: { translation: NL },
      ar: { translation: AR },
    },
    fallbackLng: "en",
  })

export default i18n
