const nl = {
  pageTitle: "Lava-scanner",
  pageDescription: "Scan uw Lavava Smart Fingerprint™ om er zeker van te zijn dat uw product echt is.",
  loading: "Bezig met laden",
  startingScanner: "Scanner starten",
  privacyPolicy: "Door Lavava te gebruiken, gaat u akkoord met onze",
  cookiesPolicy: "Cookiebeleid",
  privacyAndCookies: "Privacy en cookies",
  termsOfUse: "Gebruiksvoorwaarden",
  accept: "Aanvaarden",
  copyrightContent:
    "LAAVA, SLIMME VINGERAFDRUKKEN, VINGERAFDRUKKEN, AUTHENTICATIE VAN DINGEN, de Laava-cheque, het Laava-logo en LAAVA???? zijn handelsmerken van Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Geef toegang tot de camera.",
  bodyWarningAllowCamera:
    "Deze webscanner gebruikt de camera om vingerafdrukken te scannen. Toestemming geven voor toegang tot de camera om het opnieuw te proberen.",
  titleWarningSupportedBrowser: "Open {{browser}} om uw vingerafdruk te scannen.",
  bodyWarningSupportedBrowser: "De browser die u gebruikt wordt momenteel niet ondersteund.",
  activateTheCameraOn: "Activeer de camera op {{device}}.",
  thisWebscannerUsesTheCamera: "Deze webscanner gebruikt de camera om vingerafdrukken te scannen.",
  scanNow: "Nu scannen",
  refresh: "Vernieuwen",
  refreshThePage: "Ververs de pagina",
  loadAgain: "Laad scan.laava.id opnieuw.",
  selectAllowWhenAsked: "Selecteer toestaan wanneer om cameratoegang wordt gevraagd",
  whenAskedSelectAllow: "Wanneer u wordt gevraagd scan.laava.id wil toegang tot de camera, selecteert u Toestaan.",
  positionTheCode: "Plaats de code binnen het frame om te scannen",
  seeSupportedBrowsers: "Start browser",
  supportedBrowsersPageTitle: "Deze scanner vereist een browser met camera-ondersteuning.",
  supportedBrowsersPageBody:
    "Het besturingssysteem van uw apparaat bepaalt welke webbrowsers camera-ondersteuning hebben.",
  howUnblockCamera: "Hoe de camera te deblokkeren",
  help: "Hulp",
  supportedBrowsersPageHelpBody: "Als u vragen of opmerkingen heeft, neem dan contact met ons op.",
  contactUs: "Neem contact met ons op",
  goToBrowserSettings: "Ga naar browserinstellingen",
  findSettingBrowser: "Instellingen vind je door op de drie puntjes in de rechterbovenhoek van de browser te tikken.",
  selectSiteSettings: "Selecteer site-instellingen",
  foundAdvanced: "Deze vindt u onder het kopje geavanceerd.",
  selectCamera: "Selecteer camera",
  selectWebsite: "Selecteer 'https://scan.laava.id'",
  changePermission: "Verander de toestemming om toegang te krijgen tot uw camera, om toe te staan.",
  returnToWebscanner: "Keer terug naar de webscanner om te scannen.",
  lookForLaava: "Zoek naar Laava.",
  simplyScan: "Gewoon scannen.",
  shopWithAssurance: "Winkel met zekerheid.",
  laavaEnablesBrands:
    "Lavava stelt merken in staat u zekerheid te bieden over de kwaliteit van hun producten met behulp van nieuwe, veilige technologie die is ontwikkeld met de CSIRO.",
  aboutLaava: "Over Lava",
  knowYouReGetting: "Weet dat je het echte werk krijgt en geen vervanging",
  laavaIsANewKind:
    "Laava is een nieuw soort streepjescode die ervoor kan zorgen dat het product waarvoor u betaalt echt is en u beschermt tegen imitaties van mindere kwaliteit.",
  everyFingerprint: "Elke vingerafdruk is volledig uniek en anders dan alle andere",
  aFingerprintDoes:
    "Een vingerafdruk doet zoveel meer dan alleen aangeven welk product of welke batch u vasthoudt. Het is een individuele, volledig unieke vingerafdruk voor het exacte product in uw hand.",
  seeBeyondTheLabel: "Kijk verder dan het label en kom meer te weten over uw producten",
  scanningAllowsYou:
    "Door te scannen ziet u meer informatie dan alleen wat er op het etiket staat. Ontdek specifieke informatie over dat exacte item, zoals waar het is geproduceerd of wanneer het is verpakt.",
  ourCompany: "Ons bedrijf",
  laavaWasFounded:
    "Laava is in 2016 in Australië opgericht. Het concept en de technologische oplossing is ontwikkeld door toonaangevende experts op het gebied van optica, blockchains, marketing en consumenteninzichten.",
  needHelpScanning: "Hulp nodig bij het scannen?",
  youCanFindDetails:
    "U vindt details over scannen, samen met antwoorden op veelvoorkomende problemen, in onze helpgids.",
  getHelp: "Krijg hulp bij het scannen",
  howScan: "Hoe te scannen",
  problemsWithTheFingerprint: "Problemen met de vingerafdruk",
  commonProblems: "Veel voorkomende problemen",
  getResults:
    "Om de beste resultaten te krijgen, kunnen deze tips u helpen enkele veelvoorkomende problemen op te lossen die de kwaliteit van uw scan kunnen beïnvloeden.",
  readyToScan: "Klaar om een vingerafdruk te scannen?",
  needMoreHelp: "Meer hulp nodig?",
  scanFingerprint: "Scan uw vingerafdruk",
  bringFingerprint:
    "Breng de vingerafdruk scherp in het camerabeeld en houd deze op zijn plaats totdat de vingerafdruk wordt gedetecteerd. Dit kan even duren, afhankelijk van uw verbindingssnelheid.",
  locateSmartFingerprint: "Zoek uw Smart Fingerprint™",
  fingerprintsCanBeFound: "Vingerafdrukken zijn te vinden op allerlei alledaagse producten.",
  alignTheFingerprint: "Lijn de vingerafdruk uit met behulp van de geleider",
  alignTheGuide: "Lijn de geleider uit met de binnenste en buitenste ringen van de vingerafdruk.",
  snapPhoto: "Maak een foto",
  getYourFingerprint: "Stel uw vingerafdruk scherp en houd uw hand stil.",
  light: "Licht",
  likeAnyCamera:
    "Zoals elke camera zijn de beste resultaten bij goed licht. Zorg ervoor dat uw vingerafdruk goed verlicht is en let op verblinding.",
  keepYourHands: "Houd je handen stil",
  tryKeepYourHand:
    "Probeer tijdens het maken van de foto uw hand en camera stil te houden, zodat het beeld duidelijk is.",
  considerTheAngle: "Overweeg de hoek",
  scanYourFingerprint:
    "Scan uw vingerafdruk in een positie die zich voor de camera bevindt en het grootste deel van het beeld vult (terwijl u scherp blijft).",
  watchOutForShadow: "Pas op voor schaduwen",
  whenScanning: "Zorg er tijdens het scannen voor dat uw telefoon geen donkere schaduw over de vingerafdruk werpt.",
  haveAnyQuestion: "Heeft u vragen of feedback? We zouden graag van je horen.",
  email: "E-mail",
  startingCamera: "Camera starten",
  searching: "scannen",
  analysing: "Analyseren",
  sending: "Bezig met verzenden",
  sendingForVerification: "Verzenden ter verificatie",
  mightTakeAFewMoments: "Het kan even duren",
  placeCodeWithinFrame: "Plaats de code in het frame om te scannen",
  needHelp: "Hulp nodig?",
  googlePrivacyPolicy: "Google-privacybeleid",
  laavaPrivacy: "Laava-privacy",
  laavaHasAPrivacy:
    "Laava heeft een {{ manifest }} waarin ons beleid wordt uitgelegd dat van invloed is op u en uw privacy als gebruiker van Laava Fingerprints. Afzonderlijk hebben we ook een {{ beleid }} dat alleen van toepassing is in de beperkte omstandigheden waarin we persoonlijke informatie moeten verzamelen.",
  privacyManifesto: "Privacy Manifest",
  corporatePrivacy: "Privacybeleid van het bedrijf",
  laavaWebscannerTerm: "Lavava – Algemene voorwaarden webscanner",
  general: "Algemeen",
  websiteUse: "Gebruik van websites",
  intellectualProperty: "Intellectueel eigendom",
  limitionOfLiability: "Beperking van aansprakelijkheid",
  indemnity: "Vergoeding",
  otherWebsites: "Andere websites en derde partijen",
  entireAgreement: "Volledige overeenkomst",
  invalidity: "Ongeldigheid en afdwingbaarheid",
  privacy: "Privacy",
  applicableLaw: "Toepasselijk recht",
  howToContact: "Hoe u contact met ons kunt opnemen",
  termsP00:
    "Bedankt voor uw bezoek aan de Lavava-website (de website). De website is eigendom van en wordt beheerd door Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "Uw gebruik van de Website wordt beheerst door deze Gebruiksvoorwaarden. Door toegang te krijgen tot en gebruik te maken van de Website stemt u ermee in gebonden te zijn aan deze Gebruiksvoorwaarden.",
  termsP02:
    "Lavava kan deze Gebruiksvoorwaarden op elk moment geheel of gedeeltelijk wijzigen. De meest actuele Gebruiksvoorwaarden zullen altijd op de Website worden geplaatst. Uw latere of voortgezette gebruik van de website houdt in dat u akkoord gaat met alle wijzigingen die in deze gebruiksvoorwaarden zijn aangebracht. Als u bezwaar maakt tegen deze gebruiksvoorwaarden of tegen eventuele wijzigingen daarin, dient u het gebruik van de website te staken.",
  termsP03: "Deze voorwaarden zijn voor het laatst bijgewerkt op 26 november 2018.",
  termsP1: "mag de Website of enige Inhoud alleen gebruiken voor niet-commerciële doeleinden.",
  termsP2: "De informatie op de Website is uitsluitend bedoeld voor algemene informatiedoeleinden.",
  termsP3:
    "De Website en de informatie, links, documenten of gerelateerde afbeeldingen op de Website (samen de Inhoud) worden geleverd 'zoals ze zijn'. Elk vertrouwen dat u stelt in de Inhoud is strikt op eigen risico. U dient zelf navraag te doen om te bepalen of de Inhoud geschikt is voor uw beoogde gebruik.",
  termsP4:
    "Hoewel Laava er alles aan doet om ervoor te zorgen dat de Website te allen tijde beschikbaar en volledig operationeel is, kan uw toegang tot de Website zonder voorafgaande kennisgeving worden opgeschort in geval van systeemstoringen, onderhoud of enige andere reden buiten Laava's macht.",
  termsP5: "Lavava kan tijdelijk of permanent de volgende zaken wijzigen of stopzetten:",
  termsP6: "Website, of enige Inhoud, functie of dienst op de Website; of",
  termsP7: "het pad of de locatie van een link,",
  termsP8:
    "op elk moment zonder kennisgeving. Lavava wijst elke aansprakelijkheid af in verband met deze of soortgelijke wijzigingen, stopzettingen of onderbrekingen van de Website, of een deel van de Website.",
  termsP9:
    "Lavava behoudt zich het recht voor om uw toegang tot de website te beëindigen en verdere toegang te voorkomen zonder waarschuwing of kennisgeving als reactie op schendingen van deze gebruiksvoorwaarden door uzelf, schendingen van toepasselijke wetten of verdachte activiteiten.",
  termsP10: "Wanneer u de website gebruikt, stemt u ermee in om niet:",
  termsP11: "valse, misleidende of bedrieglijke informatie verstrekken",
  termsP12: "de website gebruiken op een manier die in strijd is of kan zijn met enige wet of regelgeving",
  termsP13:
    "de website gebruiken op een manier die ertoe kan leiden dat Laava in strijd handelt met wet- of regelgeving",
  termsP14:
    "de Website gebruiken op een manier die een ongunstige weerslag heeft op Laava of enige andere derde partij",
  termsP15:
    "informatie over andere gebruikers van de website verzamelen voor andere doeleinden dan waarvoor wij uitdrukkelijk toestemming hebben gegeven",
  termsP16:
    "actie ondernemen die, naar de mening van Laava, een onredelijke belasting vormt voor de infrastructuur van de website;",
  termsP17:
    "een computervirus of een soortgelijk programma of code verspreiden die de Website kan verstoren of uitschakelen",
  termsP18:
    "een apparaat, software of proces of andere geautomatiseerde middelen gebruiken om de website of enige inhoud voor welk doel dan ook op te halen, te schrapen, te kopiëren of te indexeren; of",
  termsP19:
    "de website of enige inhoud ophalen, schrapen, kopiëren of indexeren met als doel een database met vastgoedinformatie te creëren.",
  termsP20:
    "De website en alle intellectuele eigendom in de inhoud op de website, inclusief (zonder beperking) alle tekst, afbeeldingen, foto's, logo's of handelsmerken, is eigendom van of in licentie gegeven aan Lavava, tenzij anders aangegeven.",
  termsP21:
    "Behalve waar nodig voor het bekijken van de inhoud op de website, waar toegestaan door de wet, of waar u de uitdrukkelijke schriftelijke toestemming van Lavava hebt verkregen, mag u niet reproduceren, hercompileren, decompileren, demonteren, reverse-engineeren, afgeleide werken maken van, uitvoeren, wijzigen, aanpassen , publiceren, opslaan, gebruiken, elektronisch of anderszins verzenden, distribueren of op enige andere manier exploiteren, op welke manier dan ook, geheel of gedeeltelijk, van de website of de inhoud op de website voor andere doeleinden dan uw eigen persoonlijke, niet-commerciële gebruik .",
  termsP22: "Uw gebruik van de Website geschiedt op eigen risico.",
  termsP23:
    "Voor zover maximaal toegestaan door de wet, wijst Laava alle verklaringen, garanties of garanties af, expliciet of impliciet, inclusief maar niet beperkt tot:",
  termsP24:
    "de volledigheid, juistheid, betrouwbaarheid, geschiktheid of beschikbaarheid met betrekking tot de Website of de Inhoud",
  termsP25: "dat de Website en de Inhoud vrij zullen zijn van fouten, of dat gebreken zullen worden gecorrigeerd",
  termsP26: "dat uw toegang tot de Website ononderbroken, veilig of vrij van fouten zal zijn",
  termsP27: "dat de Website vrij is van virussen of andere schadelijke componenten; En",
  termsP28: "de kwaliteit, nauwkeurigheid of geschiktheid voor het doel van gelinkte websites of materiaal van derden.",
  termsP29:
    "Voor zover maximaal toegestaan door de wet, zijn Laava en haar directeuren, werknemers en agenten niet aansprakelijk jegens u voor enig verlies of schade van welke aard dan ook (inclusief gevolgschade) ongeacht de oorzaak, ongeacht of deze voortvloeit uit contract, onrechtmatige daad inclusief nalatigheid, statuut of anderszins voortvloeiend in verband met de Website of de Inhoud.",
  termsP30:
    "Niets in deze Gebruiksvoorwaarden is bedoeld om rechten uit te sluiten, te beperken of te wijzigen die u mogelijk hebt onder welke wet dan ook (inclusief in Australië, de Australische consumentenwetgeving) die niet mogen worden uitgesloten, beperkt of gewijzigd door middel van een overeenkomst. Als deze gebruiksvoorwaarden worden beheerst door de Australische consumentenwetgeving, is onze aansprakelijkheid jegens u voor het niet naleven van een consumentengarantie met betrekking tot diensten beperkt tot het opnieuw leveren van de diensten of de betaling voor een dergelijke herlevering.",
  termsP31:
    "U vrijwaart Laava met betrekking tot enige aansprakelijkheid opgelopen door Laava voor alle verliezen, aansprakelijkheden of schade, uitgaven, inclusief maar niet beperkt tot redelijke juridische en boekhoudkundige kosten, ongeacht de oorzaak, die Laava lijdt als gevolg van uw schending van deze Gebruiksvoorwaarden of uw gebruik van de Website.",
  termsP32: "De Website kan links bevatten naar websites van derden, advertenties of informatie op die websites.",
  termsP33:
    "Lavava heeft geen zeggenschap over de aard, inhoud en beschikbaarheid van websites van derden. Het opnemen van links naar andere websites impliceert niet noodzakelijkerwijs een sponsoring, onderschrijving of goedkeuring van die websites of de standpunten die daarin worden geuit.",
  termsP34:
    "Lavava neemt geen verantwoordelijkheid voor de inhoud op die websites. Elk gebruik van websites en informatie van derden is op eigen risico.",
  termsP35:
    "Deze gebruiksvoorwaarden vertegenwoordigen de volledige overeenkomst tussen u en Lavava met betrekking tot uw gebruik van en toegang tot de website en uw gebruik van en toegang tot de inhoud erop. Er mag geen andere term in deze gebruiksvoorwaarden worden opgenomen, behalve waar dit vereist is door wetgeving van het Gemenebest van Australië of een staat of territorium van Australië. Alle geïmpliceerde voorwaarden, behalve die geïmpliceerd door de wet en die niet uitdrukkelijk kunnen worden uitgesloten, zijn uitdrukkelijk uitgesloten.",
  termsP36:
    "Als een bepaling van deze Gebruiksvoorwaarden ongeldig is volgens de wet van een rechtsgebied, dan is de bepaling afdwingbaar in dat rechtsgebied voor zover ze niet ongeldig is, of het nu in scheidbare termen is of niet.",
  termsP37:
    "Alle persoonlijke informatie die Laava van u verzamelt, wordt behandeld in overeenstemming met de Privacy Act 1988 (Cth) en de {{policy}}",
  termsP38:
    "Raadpleeg {{manifesto}} voor meer informatie over hoe Lavava omgaat met uw persoonlijke informatie wanneer u Lavava-vingerafdrukken scant.",
  termsP39:
    "Lavava behoudt zich het recht voor om op elk moment en van tijd tot tijd de Website Scanner, of een deel daarvan, om welke reden dan ook tijdelijk of permanent te wijzigen of stop te zetten. Lavava wijst elke aansprakelijkheid af als gevolg van een stopzetting of onderbreking van de Website Scanner, of een deel daarvan",
  termsP40: "Je kunt Lavava bereiken via:",
  contact: "Contact",
  privacyOfficer: "Privacyfunctionaris",
  address: "Adres",
  phone: "Telefoon",
  laavaPrivacyManifesto: "Laava Privacymanifest",
  privacyManifestoIntroduction: "Laava – Privacymanifest",
  ourApproach: "Onze benadering van uw privacy",
  whatInformationScan: "Welke informatie wordt verzameld als ik een Lavava-vingerafdruk scan?",
  whatInformationDetect: "Welke informatie wordt verzameld wanneer we verdachte activiteiten detecteren?",
  howDoesLaava: "Hoe gebruikt Lavava cookies?",
  sharingInformation: "Het delen van informatie",
  privacyManifestoP1:
    "Dit document is ons Privacymanifest waarin ons beleid wordt uiteengezet met betrekking tot het verzamelen van informatie wanneer u een Laava Smart Fingerprint scant met een browser of WeChat Laava-scanner.",
  privacyManifestoP2:
    "We hebben een aparte {{ link }} die uiteenzet hoe we omgaan met uw persoonlijke informatie in andere zakelijke omstandigheden, bijvoorbeeld als u solliciteert naar een functie bij ons, als u een potentiële investeerder bent, vertegenwoordiger van een partner, klant, leverancier of dienstverlener van ons, of als u contact opneemt met onze helpdesk of een vraag of verzoek bij ons indient.",
  privacyManifestoP3:
    "We willen geen van uw persoonlijke gegevens verzamelen wanneer u Lavava Smart Fingerprints scant. Het respecteren van uw privacy is onze belangrijkste zorg, daarom hebben we geen namen, logins, telefoonnummers, e-mails of andere informatie nodig die u persoonlijk identificeert.",
  privacyManifestoP4:
    "Het scannen van Laava Smart Fingerprints vormt de kern van alles wat we doen. We willen een veilige, besloten en zorgeloze omgeving bieden aan iedereen die een Lavava Smart Fingerprint scant. Het scannen van Laava Smart Fingerprints betekent geen gedoe en geen nieuwsgierige blikken.",
  privacyManifestoP5:
    "We denken dat zoveel bedrijven het beter kunnen doen met betrekking tot de privacy van consumenten en we willen het voortouw nemen. Dat u gegevens kunt verzamelen, betekent niet dat u dat ook moet doen.",
  privacyManifestoP6:
    "Wanneer u een Laava Smart Fingerprint scant, maken we een foto van uw scan en andere scaninformatie, of metagegevens, die nodig zijn om de Laava Smart Fingerprint te authenticeren en de statusrecord bij te werken. Die metagegevens kunnen de URL bevatten waarvan de scan afkomstig is, de scanlocatie (meestal afgeleid van een IP-adres of GPS-locatie) en het tijdstip van elke scan. We wijzen ook een geanonimiseerde scanner-ID toe en registreren deze bij elke scan en kunnen beperkte apparaatinformatie gebruiken, zoals browser- en camera-instellingen.",
  privacyManifestoP7:
    "Belangrijk is dat alle informatie die we verzamelen wanneer u een Laava Smart Fingerprint scant, geanonimiseerd is en niet gemakkelijk kan worden gebruikt om u te identificeren (geanonimiseerde informatie). Als de foto van uw scan informatie bevat die kan worden gebruikt om u te identificeren, zullen we hier niet naar streven.",
  privacyManifestoP8:
    "Als u een Laava Smart Fingerprint scant in ons WeChat-miniprogramma, valt u ook onder het privacybeleid van WeChat.",
  privacyManifestoP9:
    "Om ervoor te zorgen dat Laava een effectief hulpmiddel is tegen namaak en fraude, en om uw scanomgeving veilig te houden en een hoge kwaliteit van service, prestaties en beveiliging te behouden, gebruiken we een reeks methoden om te bepalen of de scanactiviteit van een individuele Laava Smart Fingerprint is verdacht. Wanneer we verdachte activiteiten detecteren, kunnen we een grondige analyse van de scanactiviteit en metagegevens uitvoeren en deze informatie doorgeven aan onze klanten en partners (samen Klanten).",
  privacyManifestoP10:
    "Lavava maakt op haar website gebruik van browsercookies. Een browsercookie is een klein stukje gegevens dat op uw apparaat wordt opgeslagen om websites te helpen dingen over uw browsen te onthouden. Cookies helpen ons te zien welke delen van onze website het meest populair zijn, waardoor we uw ervaring kunnen verbeteren.",
  privacyManifestoP11:
    "Om onze website te helpen verbeteren, kan bepaalde informatie worden verzameld als statistische informatie en kan het IP-adres, browsertype, taal en toegangstijden omvatten. De tools die op onze website worden gebruikt, leggen informatie vast op een manier die u niet als individu identificeert. Uw browser of apparaat kan u toestaan cookies te blokkeren, het gebruik ervan te beperken of te verwijderen.",
  privacyManifestoP12:
    "Wanneer u Laava Smart Fingerprints scant met een browsergebaseerde scanner, kunnen cookies worden gebruikt, maar uw scangeschiedenis wordt niet opgeslagen.",
  privacyManifestoP13:
    "Onze website kan van tijd tot tijd ook websitetools van derden gebruiken, bijvoorbeeld Google Analytics en online enquêtetools. Raadpleeg het privacybeleid van Google en hun informatie op {{ link }} voor meer informatie over hoe Google gegevens verzamelt en verwerkt.",
  privacyManifestoP14:
    "Klanten zijn duidelijk geïnteresseerd in het analyseren van de Laava Smart Fingerprints-scanrecords die bij hun items horen. We delen scaninformatie over Lavava Smart Fingerprints met klanten, meestal in geaggregeerde vorm. Wanneer we informatie delen met klanten, vertellen we ze alleen wat ze moeten weten, zoals waar, wanneer en hoe vaak hun Laava Smart Fingerprint is gescand. Aangezien we uw persoonlijke gegevens niet verzamelen, kunnen we die niet met hen delen.",
  privacyManifestoP15:
    "We staan Klanten ook toe om persoonlijk contact met u op te nemen wanneer u een Lavava Smart Fingerprint scant. Als onderdeel van deze opdracht kunnen klanten u om persoonlijke informatie vragen. Dat vinden wij prima, maar bij Laava gebeurt het delen van persoonlijke informatie altijd op een transparante en opt-in basis. Je hebt altijd de leiding en kunt kiezen wat je deelt.",
  privacyManifestoP16:
    "Lavava zal uw persoonlijke informatie niet zien of verzamelen, in plaats daarvan zal alle persoonlijke informatie die u deelt, worden verzameld en behandeld door onze klanten in overeenstemming met hun privacybeleid, dat u zal worden gevraagd te accepteren.",
  privacyManifestoP17:
    "Lavava kan alle door u verstrekte informatie delen wanneer zij van mening is dat vrijgave gepast is voor naleving van de wet, om gerechtelijke procedures te vergemakkelijken of om de rechten, eigendommen of veiligheid van Laava, de gebruikers van deze website en anderen te beschermen.",
  laavaPrivacyPolicy: "Laava-privacybeleid",
  privacyPolicyT1: "Over dit zakelijke privacybeleid",
  privacyPolicyT2: "Wanneer verzamelen we uw persoonlijke gegevens?",
  privacyPolicyT3: "Welke persoonlijke informatie verzamelen en verwerken we?",
  privacyPolicyT4: "Waarom verzamelen we uw persoonlijke informatie?",
  privacyPolicyT5: "Aan wie kunnen we uw persoonlijke gegevens bekendmaken?",
  privacyPolicyT6: "Wat als we uw persoonlijke gegevens niet kunnen verzamelen?",
  privacyPolicyT7: "Bezoekers van onze website en cookies",
  privacyPolicyT8: "Hoe beschermen we uw persoonlijke gegevens?",
  privacyPolicyT9: "Direct marketing communicatie",
  privacyPolicyT10: "Hoe kunt u uw persoonlijke gegevens inzien en corrigeren?",
  privacyPolicyT11: "Hoe kunt u een privacyklacht indienen?",
  privacyPolicyT12: "Hoe u contact met ons kunt opnemen",
  privacyPolicyP1:
    "We denken dat zoveel bedrijven het beter kunnen doen met betrekking tot de privacy van consumenten en we willen het voortouw nemen. Dat u gegevens kunt verzamelen, betekent niet dat u dat ook moet doen. We waarderen en respecteren uw privacy en nemen de bescherming van uw persoonlijke gegevens serieus.",
  privacyPolicyP2:
    "Dit Corporate Privacybeleid gaat NIET over wat er met u of uw persoonlijke gegevens gebeurt wanneer u een Lavava-vingerafdruk scant. Ons beleid om geen persoonlijke informatie te verzamelen bij het scannen van Lavava-vingerafdrukken wordt behandeld in onze afzonderlijke {{ link }}",
  privacyPolicyP3:
    "In dit zakelijke privacybeleid wordt uiteengezet hoe Laava ID Pty Ltd (ABN 99617 775 578) (Laava, wij, onze, ons) omgaat met uw persoonlijke informatie in de beperkte omstandigheden waarin we persoonlijke informatie moeten verzamelen, bijvoorbeeld als u een aanvraag indient voor een baan bij ons, of wilt investeren in, of werkt met Laava (als vertegenwoordiger van een partner, klant, leverancier of dienstverlener), of als u contact opneemt met onze helpdesk of een vraag indient.",
  privacyPolicyP4:
    "Het schetst de persoonlijke informatie die we over u verzamelen, wanneer en hoe we deze gebruiken. Het schetst ook uw privacyrechten als individu en hoe u contact met ons kunt opnemen als u zich zorgen maakt over uw privacy. Dit privacybeleid kan van tijd tot tijd worden gewijzigd en daarom wilt u het misschien regelmatig bekijken.",
  privacyPolicyP5:
    "Zoals uiteengezet in ons Privacy Manifest, verzamelen we geen persoonlijke informatie in verband met het scannen van een Lavava-vingerafdruk.",
  privacyPolicyP6:
    "We zien of verzamelen uw persoonlijke informatie ook niet wanneer u ervoor kiest om persoonlijke informatie te delen met de eigenaren van merken die onze Laava-vingerafdrukken (merken) gebruiken. Dit staat ook in ons Privacy Manifest.",
  privacyPolicyP7:
    "Er zijn echter enkele beperkte omstandigheden waarin we uw persoonsgegevens rechtstreeks van u moeten verzamelen, waaronder wanneer u:",
  privacyPolicyP8:
    "met ons communiceert per telefoon, e-mail, online (ook via onze website of helpdesk), of persoonlijk",
  privacyPolicyP9: "wanneer u bij ons solliciteert",
  privacyPolicyP10: "wanneer u zich aanmeldt om belegger te worden; of",
  privacyPolicyP11:
    "wanneer u met ons communiceert als potentiële of bestaande klant, partner, leverancier of dienstverlener.",
  privacyPolicyP12:
    "Persoonlijke informatie is alle informatie of mening (waar of niet, en al dan niet vastgelegd in een materiële vorm) over u als een geïdentificeerde persoon, of een persoon die redelijkerwijs identificeerbaar is. De soorten persoonlijke informatie die we verzamelen en verwerken, kunnen zijn:",
  privacyPolicyP13: "contactgegevens, zoals uw naam, adres, telefoonnummer, e-mailadres en/of IP-adres",
  privacyPolicyP14:
    "informatie over uw rol binnen uw organisatie, als u een bestaande of potentiële partner, klant, leverancier of dienstverlener van ons bent",
  privacyPolicyP15:
    "financiële en andere relevante gegevens die u met ons deelt als u zich aanmeldt om belegger te worden",
  privacyPolicyP16:
    "als u bij ons solliciteert, kunnen we informatie verzamelen over uw kwalificaties, vaardigheden, ervaring, karakter, screeningcontroles en arbeidsverleden; En",
  privacyPolicyP17: "andere informatie die u ons verstrekt via onze helpdesk (of in een vraag die u ons stuurt).",
  privacyPolicyP18:
    "We verzamelen, bewaren, gebruiken en maken persoonlijke informatie voornamelijk bekend om te reageren op vragen, om uw sollicitatie voor een functie bij ons in overweging te nemen of om u te beschouwen als een investeerder, klant, leverancier of partner van ons. We kunnen uw persoonlijke gegevens ook verzamelen om verwante redenen, waaronder:",
  privacyPolicyP19: "administratieve doeleinden, waaronder het bijhouden van gegevens en interne rapportage",
  privacyPolicyP20: "om te communiceren met jou",
  privacyPolicyP21: "voor marketingdoeleinden; En",
  privacyPolicyP22: "om te voldoen aan toepasselijke wet- en regelgeving.",
  privacyPolicyP23:
    "In de beperkte omstandigheden die worden vermeld in sectie 2 hierboven, waarin we uw persoonlijke gegevens hebben verzameld, kunnen we uw persoonlijke gegevens aan derden bekendmaken. De soorten derden waarmee we persoonlijke informatie uitwisselen, zijn onder meer:",
  privacyPolicyP24:
    "onze dienstverleners, waaronder boekhouding, auditing, juridische zaken, bankieren, betalingen, levering, datahosting, onderzoek, werving, verzekeringen, pensioenen, salarisadministratie, training en technologiediensten",
  privacyPolicyP25:
    "voor mensen die solliciteren om bij ons te werken, referenten en aanbieders van screeningcontroles (bijv. voor achtergrond, geschiktheid om te werken, geschiktheid en strafbladcontroles); En",
  privacyPolicyP26: "aan wetshandhavingsinstanties en andere organisaties waar vereist of toegestaan door de wet.",
  privacyPolicyP27:
    "We zullen uw persoonlijke gegevens niet bekendmaken aan buitenlandse ontvangers. We kunnen uw persoonlijke informatie echter opslaan bij aanbieders van beveiligde gegevenshosting, zoals Google, die deze informatie op de volgende locaties kunnen opslaan: ({{link}}). Waar mogelijk zullen we eisen dat uw persoonlijke gegevens in Australië bewaard blijven. Als we uw persoonlijke gegevens moeten vrijgeven aan buitenlandse ontvangers, zullen we dit privacybeleid bijwerken door de landen te specificeren waarin ze zich bevinden.",
  privacyPolicyP28:
    "Als u ons de persoonlijke informatie niet verstrekt wanneer dit vereist is, zijn we mogelijk niet in staat om:",
  privacyPolicyP29: "overweeg uw sollicitatie om als werknemer bij ons in dienst te treden",
  privacyPolicyP30: "overweeg uw aanvraag om investeerder, klant, partner te worden; leverancier of dienstverlener; En",
  privacyPolicyP31: "reageren op uw vragen of verzoeken om informatie.",
  privacyPolicyP32:
    "Een browsercookie is een klein stukje gegevens dat op uw apparaat wordt opgeslagen om websites en mobiele applicaties te helpen dingen over u te onthouden. Hoewel we geen cookies gebruiken in de Laava mobiele app, gebruiken we wel cookies op onze website. Cookies helpen ons te zien welke delen van onze website het meest populair zijn, waardoor we ons kunnen concentreren op het verbeteren van die aspecten van de website die uw ervaring zullen verbeteren en om u een betere, meer intuïtieve en bevredigende ervaring te bieden. Uw browser of apparaat kan u toestaan het gebruik van cookies te blokkeren of anderszins te beperken. U kunt mogelijk ook cookies uit uw browser verwijderen.",
  privacyPolicyP33:
    "Om de service die we via onze website bieden te helpen verbeteren, kan bepaalde informatie uit uw browser worden verzameld. Deze informatie wordt verzameld als statistische informatie en omvat het IP-adres, browsertype, taal en toegangstijden. In veel gevallen leggen de tools die op onze website worden gebruikt informatie vast op een manier die u niet als individu identificeert. In andere gevallen kan informatie persoonlijke informatie zijn, in welk geval dit privacybeleid van toepassing is.",
  privacyPolicyP34:
    "Onze website kan van tijd tot tijd ook websitetools van derden gebruiken, bijvoorbeeld Google Analytics en online enquêtetools. Raadpleeg voor meer informatie over hoe Google gegevens verzamelt en verwerkt het privacybeleid van Google en hun informatie op: ({{ link }}).",
  privacyPolicyP35:
    "We nemen alle redelijke stappen om persoonlijke informatie te beschermen tegen verlies, interferentie, misbruik of ongeoorloofde toegang, wijziging of openbaarmaking. Deze stappen kunnen toegangscontroles, codering en beveiligde gebouwen omvatten. We slaan persoonlijke informatie op in zowel fysieke als elektronische vorm, soms met de hulp van derden, zoals datahostingproviders.",
  privacyPolicyP36:
    "Als u ervoor heeft gekozen om marketinginformatie van ons te ontvangen, kunnen we u van tijd tot tijd direct marketingberichten sturen met betrekking tot onze diensten.",
  privacyPolicyP37:
    "We kunnen contact met u opnemen via elektronische berichten (bijv. e-mail), online (bijv. via onze website), per post en op andere manieren, tenzij u zich later afmeldt of we onderworpen zijn aan wettelijke beperkingen. U kunt zich afmelden voor marketingcommunicatie door contact met ons op te nemen via de onderstaande contactgegevens.",
  privacyPolicyP38:
    "De juistheid van de persoonlijke informatie die we bewaren en gebruiken is belangrijk voor ons. We nemen redelijke maatregelen om ervoor te zorgen dat de persoonlijke informatie die we verwerken nauwkeurig, volledig en up-to-date is. Laat het ons weten als er fouten of wijzigingen in uw persoonlijke gegevens zijn, om ons te helpen uw persoonlijke gegevens nauwkeurig te houden.",
  privacyPolicyP39:
    "U kunt op elk moment toegang vragen tot en correctie van de persoonlijke informatie die we over u bewaren door contact op te nemen met onze Privacyfunctionaris via de contactgegevens onderaan dit Privacybeleid. In de meeste gevallen kunnen wij u snel en informeel helpen met deze verzoeken. In andere gevallen moeten we mogelijk uw identiteit verifiëren en u vragen uw verzoek schriftelijk in te dienen.",
  privacyPolicyP40:
    "kunt contact opnemen met onze Privacyfunctionaris via de contactgegevens onderaan dit Privacybeleid als u zich zorgen maakt over hoe wij met uw persoonlijke gegevens zijn omgegaan. We zullen reageren om u te laten weten wie uw zaak zal behandelen en wanneer u een verdere reactie kunt verwachten. We kunnen u om aanvullende informatie vragen met betrekking tot uw bezorgdheid, en het is mogelijk dat we andere partijen moeten inschakelen of raadplegen om uw probleem te onderzoeken en op te lossen. We zullen uw verzoek en eventuele oplossing bijhouden. Als u niet tevreden bent met de manier waarop we uw klacht hebben afgehandeld, kunt u contact opnemen met het kantoor van de Australische informatiecommissaris.",
  privacyPolicyP41:
    "Als u vragen, klachten of claims heeft met betrekking tot de Website Scanner of deze Algemene Voorwaarden, kunt u contact opnemen met Laava via:",
  problemsTitle: "Problemen met de vingerafdruk",
  problemsIntro:
    "Als de Laava Smart Fingerprint™ beschadigd lijkt of als u problemen heeft met scannen, kunt u contact met ons opnemen en zullen we ons best doen om u te helpen het probleem op te lossen.",
  tryScanningAgain: "Probeer opnieuw te scannen",
  emailUs: "Email ons",
  troubleDetectingFingerprint: "We hebben problemen met het detecteren van een vingerafdruk",
  makeSureFingerprint: "Zorg ervoor dat de vingerafdruk goed verlicht en scherp is",
  ensureInternet: "Zorg ervoor dat je internetverbinding goed is",
  tryAgain: "Probeer het nog eens",
  logIn: "Log in",
  account: "Rekening",
  emailAddress: "E-mailadres",
  password: "Wachtwoord",
  submit: "Indienen",
  thereWasAProblemLoggingIn: "Er is een probleem opgetreden bij het inloggen. Probeer het opnieuw.",
  loggedInAs: "Aangemeld als",
  logOut: "Uitloggen",

  

}


export default nl
