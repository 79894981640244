const sv = {
  pageTitle: "Laava skanner",
  pageDescription: "Skanna ditt Laava Smart Fingerprint™ för att säkerställa att din produkt är äkta.",
  loading: "Läser in",
  startingScanner: "Startar skanner",
  privacyPolicy: "Genom att använda Laava godkänner du vår",
  cookiesPolicy: "Cookies policy",
  privacyAndCookies: "Sekretess och cookies",
  termsOfUse: "Villkor",
  accept: "Acceptera",
  copyrightContent:
    "LAAVA, SMARTA Fingeravtryck, Fingeravtryck, AUTENTIFIERING AV SAKER, Laava-checken, Laava-logotypen och LAAVA ???? är varumärken som tillhör Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Tillåt kameraåtkomst.",
  bodyWarningAllowCamera:
    "Denna webbskanner använder kameran för att skanna fingeravtryck. Tillåt åtkomst till kameran för att försöka igen.",
  titleWarningSupportedBrowser: "Öppna {{browser}} för att skanna ditt fingeravtryck.",
  bodyWarningSupportedBrowser: "Den webbläsare du använder stöds för närvarande inte.",
  activateTheCameraOn: "Aktivera kameran på {{device}}.",
  thisWebscannerUsesTheCamera: "Denna webbskanner använder kameran för att skanna fingeravtryck.",
  scanNow: "Skanna nu",
  refresh: "Uppdatera",
  refreshThePage: "Uppdatera sidan",
  loadAgain: "Ladda scan.laava.id igen.",
  selectAllowWhenAsked: "Välj tillåt när du tillfrågas om kameraåtkomst",
  whenAskedSelectAllow: "På frågan scan.laava.id Skulle vilja komma åt kameran välj tillåt.",
  positionTheCode: "Placera koden inom ramen för att skanna",
  seeSupportedBrowsers: "Starta webbläsaren",
  supportedBrowsersPageTitle: "Denna skanner kräver en webbläsare med kamerastöd.",
  supportedBrowsersPageBody: "Din enhets operativsystem avgör vilka webbläsare som har kamerastöd.",
  howUnblockCamera: "Hur man låser upp kameran",
  help: "Hjälp",
  supportedBrowsersPageHelpBody: "Om du har frågor eller funderingar, vänligen kontakta oss.",
  contactUs: "Kontakta oss",
  goToBrowserSettings: "Gå till webbläsarinställningar",
  findSettingBrowser: "Du kan hitta inställningar genom att trycka på de tre prickarna i webbläsarens övre högra hörn.",
  selectSiteSettings: "Välj platsinställningar",
  foundAdvanced: "Detta finns under underrubriken avancerat.",
  selectCamera: "Välj kamera",
  selectWebsite: "Välj 'https://scan.laava.id'",
  changePermission: "Ändra behörigheten för att komma åt din kamera, för att tillåta.",
  returnToWebscanner: "Gå tillbaka till webbskannern för att skanna.",
  lookForLaava: "Leta efter Laava.",
  simplyScan: "Skanna helt enkelt.",
  shopWithAssurance: "Handla med garanti.",
  laavaEnablesBrands:
    "Laava gör det möjligt för varumärken att ge dig garantier om kvaliteten på sina produkter med hjälp av ny, säker teknik utvecklad med CSIRO.",
  aboutLaava: "Om Laava",
  knowYouReGetting: "Vet att du får den äkta varan och inte en ersättning",
  laavaIsANewKind:
    "Laava är en ny typ av streckkod som kan hjälpa till att säkerställa att produkten du betalar för är den äkta varan och skydda dig från imitationer av lägre kvalitet.",
  everyFingerprint: "Varje fingeravtryck är helt unikt och olik alla andra",
  aFingerprintDoes:
    "Ett fingeravtryck gör så mycket mer än att bara ange vilken produkt eller batch du har. Det är ett individuellt, helt unikt fingeravtryck för den exakta produkten i din hand.",
  seeBeyondTheLabel: "Se bortom etiketten och veta mer om dina produkter",
  scanningAllowsYou:
    "Genom att skanna kan du se mer information än bara det som står på etiketten. Ta reda på specifik information om den exakta varan, till exempel var den tillverkades eller när den förpackades.",
  ourCompany: "Vårt bolag",
  laavaWasFounded:
    "Laava grundades i Australien 2016. Konceptet och tekniklösningen har utvecklats av världsledande experter inom området optik, blockkedjor, marknadsföring, konsumentinsikter.",
  needHelpScanning: "Behöver du hjälp med att skanna?",
  youCanFindDetails:
    "Du kan hitta information om att skanna, tillsammans med svar på vanliga problem, i vår hjälpguide.",
  getHelp: "Få hjälp med skanning",
  howScan: "Hur man skannar",
  problemsWithTheFingerprint: "Problem med fingeravtrycket",
  commonProblems: "Vanliga problem",
  getResults:
    "För att få bästa resultat kan dessa tips hjälpa dig att övervinna några vanliga problem som kan påverka kvaliteten på din skanning.",
  readyToScan: "Är du redo att skanna ett fingeravtryck?",
  needMoreHelp: "Behöver du mer hjälp?",
  scanFingerprint: "Skanna ditt fingeravtryck",
  bringFingerprint:
    "Fokusera på fingeravtrycket i kameravyn och håll på plats tills fingeravtrycket upptäcks. Detta kan ta en stund beroende på din anslutningshastighet.",
  locateSmartFingerprint: "Leta reda på ditt Smart Fingerprint™",
  fingerprintsCanBeFound: "Fingeravtryck kan hittas på en mängd olika vardagsprodukter.",
  alignTheFingerprint: "Rikta in fingeravtrycket med hjälp av guiden",
  alignTheGuide: "Rikta in guiden mot de inre och yttre ringen på fingeravtrycket.",
  snapPhoto: "Ta ett foto",
  getYourFingerprint: "Få ditt fingeravtryck i fokus och håll din hand stadig.",
  light: "Ljus",
  likeAnyCamera:
    "Som vilken kamera som helst är det bästa resultatet i bra ljus. Se till att ditt fingeravtryck är väl upplyst och se upp för bländning.",
  keepYourHands: "Håll händerna stadiga",
  tryKeepYourHand: "Försök att hålla handen och kameran stilla när du tar bilden, så att bilden blir tydlig.",
  considerTheAngle: "Tänk på vinkeln",
  scanYourFingerprint:
    "Skanna ditt fingeravtryck i en position som är framför kameran och fyller större delen av vyn (medan du är i fokus).",
  watchOutForShadow: "Se upp för skuggor",
  whenScanning: "När du skannar, se till att din telefon inte kastar en mörk skugga över fingeravtrycket.",
  haveAnyQuestion: "Har du frågor eller feedback? Vi skulle älska att höra från dig.",
  email: "E-post",
  startingCamera: "Startar kamera",
  searching: "Läser in",
  analysing: "Analyserar",
  sending: "Sändning",
  sendingForVerification: "Skickar för verifiering",
  mightTakeAFewMoments: "Det kan ta några ögonblick",
  placeCodeWithinFrame: "Placera koden i ramen för att skanna",
  needHelp: "Behövs hjälp?",
  googlePrivacyPolicy: "Googles sekretesspolicy",
  laavaPrivacy: "Laava Sekretess",
  laavaHasAPrivacy:
    "Laava har ett {{ manifest }} som förklarar våra policyer som påverkar dig och din integritet som användare av Laava Fingerprints. Separat har vi också en {{ policy }} som endast gäller under de begränsade omständigheter där vi måste samla in personlig information.",
  privacyManifesto: "Sekretessmanifest",
  corporatePrivacy: "Företags sekretesspolicy",
  laavaWebscannerTerm: "Laava – Webscanners användarvillkor",
  general: "Allmän",
  websiteUse: "Användning av webbplatsen",
  intellectualProperty: "Immateriella rättigheter",
  limitionOfLiability: "Ansvarsbegränsning",
  indemnity: "Ersättning",
  otherWebsites: "Andra webbplatser och tredje parter",
  entireAgreement: "Hela avtalet",
  invalidity: "Ogiltighet och verkställbarhet",
  privacy: "Integritet",
  applicableLaw: "Tillämplig lag",
  howToContact: "Hur du kontaktar oss",
  termsP00:
    "Tack för att du besöker Laavas webbplats (webbplatsen). Webbplatsen ägs och drivs av Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "Din användning av webbplatsen styrs av dessa användarvillkor. Genom att gå in på och använda webbplatsen samtycker du till att vara bunden av dessa användarvillkor.",
  termsP02:
    "Laava kan ändra delar av eller alla dessa användarvillkor när som helst. De senaste användarvillkoren kommer alltid att publiceras på webbplatsen. Din efterföljande eller fortsatta användning av webbplatsen kommer att utgöra ditt godkännande av alla ändringar som görs i dessa användarvillkor. Om du invänder mot dessa användarvillkor, eller mot eventuella ändringar som görs i dem, bör du sluta använda webbplatsen.",
  termsP03: "Dessa villkor uppdaterades senast den 26 november 2018.",
  termsP1: "Du får endast använda webbplatsen eller något innehåll för icke-kommersiella ändamål.",
  termsP2: "Informationen på webbplatsen är endast för allmän information.",
  termsP3:
    "Webbplatsen och informationen, länkarna, dokumenten eller relaterad grafik som finns på webbplatsen (tillsammans, innehållet) tillhandahålls 'i befintligt skick'. All tillit du sätter på innehållet sker helt på din egen risk. Du måste göra dina egna förfrågningar för att avgöra om innehållet är lämpligt för din avsedda användning.",
  termsP4:
    "Även om Laava gör alla rimliga ansträngningar för att säkerställa att webbplatsen är tillgänglig och fullt fungerande hela tiden, kan din åtkomst till webbplatsen stängas av utan förvarning i händelse av systemfel, underhåll eller någon anledning utanför Laavas kontroll.",
  termsP5: "Laava kan ändra eller avbryta, antingen tillfälligt eller permanent,",
  termsP6: "Webbplats, eller något innehåll, funktion eller tjänst på webbplatsen; eller",
  termsP7: "sökvägen eller platsen för en länk,",
  termsP8:
    "när som helst utan förvarning. Laava frånsäger sig allt ansvar i samband med dessa, eller liknande, ändringar, avbrott eller avbrott av webbplatsen, eller någon del av webbplatsen.",
  termsP9:
    "Laava förbehåller sig rätten att avsluta din åtkomst till webbplatsen och förhindra ytterligare åtkomst utan förvarning eller meddelande som svar på brott mot dessa användarvillkor av dig själv, brott mot tillämpliga lagar eller misstänkt aktivitet.",
  termsP10: "När du använder webbplatsen samtycker du till att inte:",
  termsP11: "tillhandahålla falsk, vilseledande eller vilseledande information",
  termsP12: "använda webbplatsen på ett sätt som bryter mot, eller som kan bryta mot, någon lag eller förordning",
  termsP13: "använda webbplatsen på ett sätt som kan orsaka att Laava bryter mot någon lag eller förordning",
  termsP14: "använda webbplatsen på ett sätt som reflekterar ogynnsamt för Laava eller någon annan tredje part",
  termsP15:
    "samla in information om andra användare av webbplatsen för något annat ändamål än vad som uttryckligen godkänts av oss",
  termsP16: "vidta någon åtgärd som, enligt Laavas uppfattning, lägger en orimlig börda på webbplatsens infrastruktur;",
  termsP17: "distribuera ett datavirus eller liknande program eller kod som kan störa eller inaktivera webbplatsen",
  termsP18:
    "använda en enhet, någon programvara eller process, eller andra automatiserade metoder för att hämta, skrapa, kopiera eller indexera webbplatsen eller något innehåll för något syfte; eller",
  termsP19:
    "hämta, skrapa, kopiera eller indexera webbplatsen eller något innehåll i syfte att skapa en databas med fastighetsinformation.",
  termsP20:
    "Webbplatsen och all immateriell egendom i innehållet på webbplatsen, inklusive (utan begränsning) all text, grafik, fotografier, logotyper eller varumärken ägs av, eller licensieras till, Laava, om inte annat anges.",
  termsP21:
    "Förutom när det är nödvändigt för att se innehållet på webbplatsen, där det är tillåtet enligt lag, eller där du har fått Laavas uttryckliga skriftliga tillstånd, får du inte reproducera, omkompilera, dekompilera, demontera, bakåtkonstruera, skapa härledda verk från, utföra, modifiera, anpassa , publicera, lagra, använda, överföra elektroniskt eller på annat sätt, distribuera, eller på annat sätt utnyttja, på något sätt, helt eller delvis, webbplatsen eller innehållet på webbplatsen för andra syften än ditt eget personliga, icke-kommersiella bruk .",
  termsP22: "Din användning av webbplatsen sker på egen risk.",
  termsP23:
    "I den maximala utsträckning som lagen tillåter, avsäger sig Laava alla utfästelser, garantier eller garantier, uttryckliga eller underförstådda, inklusive men inte begränsat till:",
  termsP24:
    "fullständigheten, noggrannheten, tillförlitligheten, lämpligheten eller tillgängligheten med avseende på webbplatsen eller innehållet",
  termsP25: "att webbplatsen och innehållet kommer att vara fria från fel, eller att defekter kommer att rättas till",
  termsP26: "att din åtkomst till webbplatsen kommer att vara oavbruten, säker eller felfri",
  termsP27: "att webbplatsen är fri från virus eller andra skadliga komponenter; och",
  termsP28:
    "kvaliteten, noggrannheten eller lämpligheten för alla länkade webbplatser eller material från tredje part.",
  termsP29:
    "I den utsträckning som lagen tillåter är Laava och dess styrelseledamöter, anställda och agenter inte ansvariga gentemot dig för någon förlust eller skada av något slag (inklusive följdförlust) oavsett vilket orsakat, vare sig det är i kontrakt, skadestånd inklusive vårdslöshet, lag eller annat som uppstår. i samband med webbplatsen eller innehållet.",
  termsP30:
    "Ingenting i dessa användarvillkor är avsett att utesluta, begränsa eller modifiera rättigheter som du kan ha enligt någon lag (inklusive i Australien, den australiensiska konsumentlagen) som inte får uteslutas, begränsas eller modifieras genom avtal. Om dessa användarvillkor regleras av den australiensiska konsumentlagstiftningen, är vårt ansvar gentemot dig för underlåtenhet att följa en konsumentgaranti med avseende på någon tjänst begränsad till återförsörjning av tjänsterna eller betalning för sådan återförsörjning.",
  termsP31:
    "Du håller Laava skadeslös med avseende på allt ansvar som Laava ådragit sig för eventuella förluster, skulder eller skador, utgifter, inklusive utan begränsning, rimliga juridiska och redovisningsmässiga avgifter, hur de än orsakas, som drabbas av Laava som ett resultat av ditt brott mot dessa användarvillkor eller din användning av webbplatsen.",
  termsP32:
    "Webbplatsen kan innehålla länkar till tredje parts webbplatser, annonser eller information på dessa webbplatser.",
  termsP33:
    "Laava har ingen kontroll över arten, innehållet och tillgängligheten på tredje parts webbplatser. Inkluderandet av länkar till andra webbplatser innebär inte nödvändigtvis sponsring, stöd eller godkännande av dessa webbplatser eller de åsikter som uttrycks på dem.",
  termsP34:
    "Laava tar inget ansvar för innehållet på dessa webbplatser. All användning av tredje parts webbplatser och information sker på egen risk.",
  termsP35:
    "Dessa användarvillkor representerar hela avtalet mellan dig och Laava angående din användning och åtkomst till webbplatsen och din användning och åtkomst till innehållet på den. Inga andra termer ska inkluderas i dessa Användarvillkor förutom där det krävs att det inkluderas av någon lagstiftning i Commonwealth of Australia eller någon stat eller territorium i Australien. Alla underförstådda villkor utom de som anges i lag och som inte uttryckligen kan uteslutas är uttryckligen uteslutna.",
  termsP36:
    "Om någon bestämmelse i dessa användarvillkor är ogiltig enligt lagen i någon jurisdiktion, är bestämmelsen verkställbar i den jurisdiktionen i den mån den inte är ogiltig, oavsett om den är avskiljbar eller inte.",
  termsP37:
    "All personlig information som Laava samlar in från dig kommer att hanteras i enlighet med Privacy Act 1988 (Cth) och {{policy}}",
  termsP38:
    "För mer information om hur Laava hanterar din personliga information när du skannar Laava Fingerprints, se {{manifest}}",
  termsP39:
    "Laava förbehåller sig rätten att när som helst och från tid till annan modifiera, avbryta antingen tillfälligt eller permanent Webbplatsskannern, eller någon del därav, av någon anledning. Laava frånsäger sig allt ansvar till följd av eventuellt avbrott eller avbrott av webbplatsskannern, eller någon del därav",
  termsP40: "Du kan kontakta Laava via:",
  contact: "Kontakt",
  privacyOfficer: "Integritetsombud",
  address: "Adress",
  phone: "Telefon",
  laavaPrivacyManifesto: "Laava Privacy Manifesto",
  privacyManifestoIntroduction: "Laava – Sekretessmanifest",
  ourApproach: "Vår inställning till din integritet",
  whatInformationScan: "Vilken information samlas in när jag skannar ett Laava-fingeravtryck?",
  whatInformationDetect: "Vilken information samlas in när vi upptäcker misstänkt aktivitet?",
  howDoesLaava: "Hur använder Laava cookies?",
  sharingInformation: "Dela information",
  privacyManifestoP1:
    "Detta dokument är vårt integritetsmanifest som beskriver vår policy angående insamling av information när du skannar ett Laava Smart Fingerprint med en webbläsare eller WeChat Laava-skanner.",
  privacyManifestoP2:
    "Vi har en separat {{ länk }} som anger hur vi hanterar dina personuppgifter i andra affärsförhållanden, till exempel om du ansöker om en tjänst hos oss, om du är en potentiell investerare, representant för en partner, kund, leverantör eller vår tjänsteleverantör, eller om du kontaktar vår helpdesk eller skickar en fråga eller förfrågan till oss.",
  privacyManifestoP3:
    "Vi vill inte samla in någon av dina personuppgifter när du skannar Laava Smart Fingerprints. Att respektera din integritet är vår viktigaste fråga, och det är därför vi inte kräver namn, inloggningar, telefonnummer, e-postmeddelanden eller annan information som identifierar dig personligen.",
  privacyManifestoP4:
    "Skanning av Laava Smart Fingerprints är kärnan i allt vi gör. Vi vill tillhandahålla en säker, privat och bekymmersfri miljö för alla som skannar ett Laava Smart Fingerprint. Att skanna Laava Smart Fingerprints innebär inget krångel och inga nyfikna ögon.",
  privacyManifestoP5:
    "Vi tror att så många företag kan göra bättre ifrån sig när det gäller konsumenternas integritet och vi vill visa vägen. Bara för att du kan samla in data betyder det inte att du borde.",
  privacyManifestoP6:
    "När du skannar ett Laava Smart Fingerprint, tar vi en bild av din skanning och annan skanningsinformation, eller metadata, som krävs för att autentisera Laava Smart Fingerprint och uppdatera dess statuspost. Dessa metadata kan inkludera URL:en från vilken skanningen kom, skanningsplatsen (vanligtvis härledd från en IP-adress eller GPS-plats) och tidpunkten för varje skanning. Vi tilldelar och registrerar också ett anonymiserat skanner-ID med varje skanning och kan använda begränsad enhetsinformation som webbläsare och kamerainställningar.",
  privacyManifestoP7:
    "Viktigt är att all information vi samlar in när du skannar ett Laava Smart Fingerprint är anonymiserad och kan inte enkelt användas för att identifiera dig (Anonymiserad information). Om bilden av din skanning innehåller information som kan användas för att identifiera dig, kommer vi inte att försöka göra det.",
  privacyManifestoP8:
    "Om du skannar ett Laava Smart Fingerprint i vårt WeChat-miniprogram kommer du också att omfattas av WeChats integritetspolicy.",
  privacyManifestoP9:
    "För att säkerställa att Laava är ett effektivt verktyg mot förfalskning och bedrägerier, och för att hålla din skanningsmiljö säker och upprätthålla en hög kvalitet på tjänster, prestanda och säkerhet, använder vi en rad olika metoder för att avgöra om skanningsaktiviteten för en enskild Laava Smart Fingerprint är misstänksam. När vi upptäcker misstänkt aktivitet kan vi göra en grundlig analys av skanningsaktiviteten och metadata, samt vidarebefordra denna information till våra kunder och partners (tillsammans Kunder ).",
  privacyManifestoP10:
    "Laava använder webbläsarcookies på sin webbplats. En webbläsarcookie är en liten bit data som lagras på din enhet för att hjälpa webbplatser att komma ihåg saker om din surfning. Cookies hjälper oss att se vilka delar av vår webbplats som är mest populära, vilket gör att vi kan förbättra din upplevelse.",
  privacyManifestoP11:
    "För att hjälpa till att förbättra vår webbplats kan viss information samlas in som statistisk information och kan inkludera IP-adress, webbläsartyp, språk och åtkomsttider. De verktyg som används på vår webbplats registrerar information på ett sätt som inte identifierar dig som individ. Din webbläsare eller enhet kan tillåta dig att blockera, begränsa användningen av eller ta bort cookies.",
  privacyManifestoP12:
    "När du skannar Laava Smart Fingerprints med en webbläsarbaserad skanner kan cookies användas, men din skanningshistorik kommer inte att lagras.",
  privacyManifestoP13:
    "Vår webbplats kan också använda webbplatsverktyg från tredje part från tid till annan, till exempel Google Analytics och onlineundersökningsverktyg. Mer information om hur Google samlar in och behandlar data finns i Googles sekretesspolicy och informationen om deras information på {{ länk }}",
  privacyManifestoP14:
    "Kunder är uppenbarligen intresserade av att analysera Laava Smart Fingerprints scanningsposter som är kopplade till deras föremål. Vi delar skanningsinformation om Laava Smart Fingerprints med kunder, vanligtvis i aggregerad form. När vi delar information med kunder berättar vi bara för dem vad de behöver veta, till exempel var, när och hur många gånger deras Laava Smart Fingerprint skannades. Eftersom vi inte samlar in din personliga information kan vi inte dela den med dem.",
  privacyManifestoP15:
    "Vi tillåter också kunder att kontakta dig på personlig basis när du skannar ett Laava Smart Fingerprint. Som en del av detta engagemang kan kunder begära personlig information från dig. Vi tycker att det är bra, men på Laava görs delning av all personlig information alltid på en transparent och opt-in-basis. Du är alltid ansvarig och kan välja vad du delar.",
  privacyManifestoP16:
    "Laava kommer inte att se eller samla in din personliga information, istället kommer all personlig information du delar att samlas in och hanteras av våra kunder i enlighet med deras integritetspolicy, som du kommer att bli ombedd att acceptera.",
  privacyManifestoP17:
    "Laava kan komma att dela all information du tillhandahåller när den anser att frigivning är lämplig för att följa lagen, för att underlätta domstolsförfaranden eller skydda rättigheterna, egendomen eller säkerheten för Laava, användarna av denna webbplats och andra.",
  laavaPrivacyPolicy: "Laavas sekretesspolicy",
  privacyPolicyT1: "Om denna Corporate Privacy Policy",
  privacyPolicyT2: "När samlar vi in dina personuppgifter?",
  privacyPolicyT3: "Vilken personlig information samlar vi in och hanterar?",
  privacyPolicyT4: "Varför samlar vi in dina personuppgifter?",
  privacyPolicyT5: "Vem kan vi lämna ut din personliga information till?",
  privacyPolicyT6: "Vad händer om vi inte kan samla in din personliga information?",
  privacyPolicyT7: "Besökare på vår webbplats och cookies",
  privacyPolicyT8: "Hur skyddar vi dina personuppgifter?",
  privacyPolicyT9: "Direkt marknadsföringskommunikation",
  privacyPolicyT10: "Hur kan du komma åt och korrigera dina personuppgifter?",
  privacyPolicyT11: "Hur kan du göra ett integritetsklagomål?",
  privacyPolicyT12: "Hur du kontaktar oss",
  privacyPolicyP1:
    "Vi tror att så många företag kan göra bättre ifrån sig när det gäller konsumenternas integritet och vi vill visa vägen. Bara för att du kan samla in data betyder det inte att du borde. Vi värdesätter och respekterar din integritet och tar skyddet av din personliga information på allvar.",
  privacyPolicyP2:
    "Denna integritetspolicy handlar INTE om vad som händer med dig eller din personliga information när du skannar ett Laava-fingeravtryck. Vår policy att inte samla in personlig information när vi skannar Laava Fingerprints täcks av vår separata {{ länk }}",
  privacyPolicyP3:
    "Denna integritetspolicy anger hur Laava ID Pty Ltd (ABN 99617 775 578) (Laava, vi, vår, oss) hanterar din personliga information under de begränsade omständigheter under vilka vi måste samla in personlig information, till exempel om du ansöker om en jobba hos oss, eller söka investera i, eller arbeta med Laava (som representant för en partner, kund, leverantör eller tjänsteleverantör), eller om du kontaktar vår helpdesk eller skickar en förfrågan.",
  privacyPolicyP4:
    "Den beskriver den personliga information vi samlar in om dig, när och hur vi använder den. Den beskriver också dina integritetsrättigheter som individ och hur du kontaktar oss om du har några integritetsproblem. Denna integritetspolicy kan ändras från tid till annan och du kanske vill granska den regelbundet.",
  privacyPolicyP5:
    "Som anges i vårt integritetsmanifest samlar vi inte in någon personlig information i samband med skanningen av ett Laava-fingeravtryck.",
  privacyPolicyP6:
    "Vi ser eller samlar inte heller in din personliga information när du väljer att dela personlig information med ägarna till varumärken som använder våra Laava Fingerprints (Varumärken). Detta tas också upp i vårt sekretessmanifest.",
  privacyPolicyP7:
    "Det finns dock vissa begränsade omständigheter under vilka vi behöver samla in din personliga information direkt från dig, inklusive när du:",
  privacyPolicyP8:
    "kommunicera med oss via telefon, e-post, online (inklusive via vår webbplats eller helpdesk) eller personligen",
  privacyPolicyP9: "när du söker en tjänst hos oss",
  privacyPolicyP10: "när du ansöker om att bli investerare; eller",
  privacyPolicyP11:
    "när du interagerar med oss som en potentiell eller pågående kund, partner, leverantör eller tjänsteleverantör.",
  privacyPolicyP12:
    "Personlig information är all information eller åsikt (oavsett om den är sann eller inte, och vare sig den är registrerad i materiell form eller inte) om dig som en identifierad individ, eller en individ som är rimligt identifierbar. De typer av personlig information vi samlar in och hanterar kan vara:",
  privacyPolicyP13: "kontaktinformation, såsom ditt namn, adress, telefonnummer, e-post och/eller IP-adress",
  privacyPolicyP14:
    "information om din roll inom din organisation, om du är en befintlig eller potentiell partner, kund, leverantör eller tjänsteleverantör till vår",
  privacyPolicyP15:
    "finansiella och andra relevanta uppgifter som du delar med oss om du ansöker om att bli investerare",
  privacyPolicyP16:
    "om du ansöker om en tjänst hos oss kan vi samla in information om dina kvalifikationer, kompetenserfarenhet, karaktär, screeningkontroller och anställningshistorik; och",
  privacyPolicyP17: "annan information du lämnar till oss via vår helpdesk (eller i alla frågor du skickar till oss).",
  privacyPolicyP18:
    "Vi samlar in, håller, använder och avslöjar personlig information främst för att svara på frågor, för att överväga din ansökan om en tjänst hos oss eller för att betrakta dig som en investerare, kund, leverantör eller partner till vår. Vi kan också samla in din personliga information av relaterade skäl, inklusive:",
  privacyPolicyP19: "administrativa ändamål inklusive journalföring och intern rapportering",
  privacyPolicyP20: "att kommunicera med dig",
  privacyPolicyP21: "för marknadsföringsändamål; och",
  privacyPolicyP22: "för att följa tillämpliga lagar och förordningar.",
  privacyPolicyP23:
    "Under de begränsade omständigheter som anges i avsnitt 2 ovan där vi har samlat in din personliga information kan vi komma att lämna ut din personliga information till tredje part. De typer av tredje parter som vi utbyter personlig information med inkluderar:",
  privacyPolicyP24:
    "våra tjänsteleverantörer, inklusive redovisning, revision, juridik, bank, betalning, leverans, datahosting, forskning, rekrytering, försäkring, pensionspension, löner, utbildning och tekniktjänster",
  privacyPolicyP25:
    "för personer som ansöker om att arbeta hos oss, domare och leverantörer av screeningkontroller (t.ex. för bakgrund, behörighet att arbeta, lämplighet och kontroller av brottsregister); och",
  privacyPolicyP26:
    "till brottsbekämpande myndigheter och andra organisationer där det krävs eller tillåts enligt lag.",
  privacyPolicyP27:
    "Vi kommer inte att avslöja din personliga information till några utländska mottagare. Vi kan dock lagra din personliga information hos leverantörer av säkra datavärdar som Google, som kan lagra informationen på följande platser: ({{länk}}). Där det är möjligt kommer vi att kräva att din personliga information förblir lagrad i Australien. Om vi behöver lämna ut din personliga information till utländska mottagare kommer vi att uppdatera denna integritetspolicy genom att ange i vilka länder de befinner sig.",
  privacyPolicyP28: "Om du inte förser oss med personlig information när det krävs, kanske vi inte kan:",
  privacyPolicyP29: "överväga din ansökan om att bli anställd hos oss",
  privacyPolicyP30:
    "överväga din ansökan om att bli investerare, kund, partner; leverantör eller tjänsteleverantör; och",
  privacyPolicyP31: "svara på dina frågor eller förfrågningar om information.",
  privacyPolicyP32:
    "En webbläsarcookie är en liten bit data som lagras på din enhet för att hjälpa webbplatser och mobilapplikationer att komma ihåg saker om dig. Även om vi inte använder cookies i Laavas mobilapp använder vi cookies på vår webbplats. Cookies hjälper oss att se vilka delar av vår webbplats som är mest populära, vilket gör att vi kan fokusera på att förbättra de aspekter av webbplatsen som kommer att förbättra din upplevelse, och för att ge dig en bättre, mer intuitiv och tillfredsställande upplevelse. Din webbläsare eller enhet kan tillåta dig att blockera eller på annat sätt begränsa användningen av cookies. Du kanske också kan ta bort cookies från din webbläsare.",
  privacyPolicyP33:
    "För att hjälpa till att förbättra tjänsten vi tillhandahåller via vår webbplats kan viss information samlas in från din webbläsare. Denna information samlas in som statistisk information och inkluderar IP-adress, webbläsartyp, språk och åtkomsttider. I många fall registrerar de verktyg som används på vår webbplats information på ett sätt som inte identifierar dig som individ. I andra fall kan information vara personlig information i vilket fall denna integritetspolicy kommer att gälla.",
  privacyPolicyP34:
    "Vår webbplats kan också använda webbplatsverktyg från tredje part från tid till annan, till exempel Google Analytics och onlineundersökningsverktyg. För mer om hur Google samlar in och behandlar data, se Googles sekretesspolicy och deras information på: ({{ länk }}).",
  privacyPolicyP35:
    "Vi vidtar alla rimliga åtgärder för att hålla personlig information skyddad från förlust, störningar, missbruk eller obehörig åtkomst, modifiering eller avslöjande. Dessa steg kan inkludera åtkomstkontroller, kryptering och säkra lokaler. Vi lagrar personlig information i både fysisk och elektronisk form, ibland med hjälp av tredje part såsom datavärdleverantörer.",
  privacyPolicyP36:
    "Från tid till annan, om du har valt att ta emot marknadsföringsinformation från oss, kan vi skicka dig direkt marknadsföringskommunikation angående våra tjänster.",
  privacyPolicyP37:
    "Vi kan kontakta dig via elektroniska meddelanden (t.ex. e-post), online (t.ex. via vår webbplats), via post och på annat sätt, såvida du inte senare väljer bort det eller vi är föremål för juridiska begränsningar. Du kan välja bort marknadsföringskommunikation genom att kontakta oss med kontaktuppgifterna nedan.",
  privacyPolicyP38:
    "Noggrannheten i den personliga information vi har och använder är viktig för oss. Vi vidtar rimliga åtgärder för att säkerställa att den personliga information vi hanterar är korrekt, fullständig och uppdaterad. För att hjälpa oss att hålla din personliga information korrekt, vänligen meddela oss om det finns några fel eller ändringar i din personliga information.",
  privacyPolicyP39:
    "Du kan när som helst begära tillgång till och korrigering av den personliga information vi har om dig genom att kontakta vår integritetsansvarig via kontaktuppgifterna längst ner i denna integritetspolicy. I de flesta fall kan vi hjälpa dig snabbt och informellt med dessa förfrågningar. I andra fall kan vi behöva verifiera din identitet och be dig att göra din begäran skriftligen.",
  privacyPolicyP40:
    "Du kan kontakta vår sekretessansvarige med hjälp av kontaktuppgifterna längst ner i denna integritetspolicy om du har några frågor om hur vi har hanterat dina personuppgifter. Vi kommer att svara för att meddela dig vem som kommer att hantera ditt ärende och när du kan förvänta dig ytterligare svar. Vi kan begära ytterligare information från dig angående din oro, och vi kan behöva kontakta eller rådgöra med andra parter för att undersöka och hantera ditt problem. Vi kommer att föra register över din begäran och eventuella lösningar. Om du inte är nöjd med det sätt på vilket vi har hanterat ditt klagomål kan du kontakta Office of the Australian Information Commissioner.",
  privacyPolicyP41:
    "Om du har några frågor, klagomål eller anspråk i samband med webbplatsskannern eller dessa villkor kan du kontakta Laava på:",
  problemsTitle: "Problem med fingeravtrycket",
  problemsIntro:
    "Om Laava Smart Fingerprint™ verkar skadat eller om du har problem med att skanna kan du kontakta oss så ska vi göra vårt bästa för att hjälpa dig lösa problemet.",
  tryScanningAgain: "Försök att skanna igen",
  emailUs: "Mejla oss",
  troubleDetectingFingerprint: "Vi har problem med att upptäcka ett fingeravtryck",
  makeSureFingerprint: "Se till att fingeravtrycket är väl upplyst och i fokus",
  ensureInternet: "Se till att din internetanslutning är bra",
  tryAgain: "Försök igen",
  logIn: "Logga In",
  account: "Konto",
  emailAddress: "E-postadress",
  password: "Lösenord",
  submit: "Skicka in",
  thereWasAProblemLoggingIn: "Det gick inte att logga in. Försök igen.",
  loggedInAs: "Inloggad som",
  logOut: "Logga ut",
}




export default sv
