/* eslint-disable no-useless-concat */
const ar = {
  pageTitle: '\u202Bماسح\u202C Laava\u202B',
  pageDescription: '\u202Bامسح بصمة\u202C Laava\u202C \u202Bالذكية™ الخاصة بك لضمان أن المنتج الخاص بك أصلي.\u202C',
  loading: 'جارٍ التحميل',
  startingScanner: 'يتم بدء تشغيل الماسح الضوئي',
  privacyPolicy: '\u202Bباستخدام\u202C Laava\u202C \u202B، أنت توافق على\u202C',
  cookiesPolicy: 'سياسة ملفات تعريف الارتباط',
  privacyAndCookies: 'الخصوصية وملفات تعريف الارتباط',
  termsOfUse: 'شروط الاستخدام',
  accept: 'قبول',
  copyrightContent:
    '\u202BLAAVA، البصمات الذكية، بصمات الأصابع، التحقق من الأشياء، فحص Laava، شعار Laava، و LAAVA 物联验证 هي علامات تجارية لشركة Laava ID Pty Ltd.\u202C',

  titleWarningAllowCamera: '\u202Bيرجى السماح بالوصول إلى الكاميرا.\u202C',
  bodyWarningAllowCamera:
    '\u202Bيستخدم هذا الماسح الكاميرا لمسح بصمات الأصابع. اسمح بالوصول إلى الكاميرا للمحاولة مرة أخرى.\u202C',
  titleWarningSupportedBrowser: '\u202Bيرجى فتح\u202C {{browser}} \u202Bلمسح بصمتك.\u202C',
  bodyWarningSupportedBrowser: '\u202Bالمتصفح الذي تستخدمه غير مدعوم حاليًا.\u202C',
  activateTheCameraOn: '\u202Bقم بتنشيط الكاميرا على\u202C {{device}}\u202C',
  thisWebscannerUsesTheCamera: '\u202Bيستخدم هذا الماسح الكاميرا لمسح بصمات الأصابع.\u202C',
  scanNow: '\u202Bامسح الآن\u202C',
  refresh: '\u202Bتحديث\u202C',
  refreshThePage: '\u202Bقم بتحديث الصفحة\u202C',
  loadAgain: '\u202Bأعد تحميل scan.laava.id.\u202C',
  selectAllowWhenAsked: '\u202Bاختر السماح عند طلب الوصول إلى الكاميرا\u202C',
  whenAskedSelectAllow: '\u202Bعند مطالبتك بـ “scan.laava.id يريد الوصول إلى الكاميرا”، اختر السماح.\u202C',
  positionTheCode: '\u202Bتأكد من أن الكود في التركيز ومركّز\u202C',
  seeSupportedBrowsers: '\u202Bتشغيل المتصفح\u202C',
  supportedBrowsersPageTitle: '\u202Bيتطلب هذا الماسح متصفحًا يدعم الكاميرا.\u202C',
  supportedBrowsersPageBody: '\u202Bسيحدد نظام تشغيل جهازك المتصفحات التي تدعم الكاميرا.\u202C',
  howUnblockCamera: '\u202Bكيفية إلغاء حظر الكاميرا\u202C',
  help: '\u202Bمساعدة\u202C',
  supportedBrowsersPageHelpBody: '\u202Bإذا كان لديك أي أسئلة أو استفسارات، يرجى الاتصال بنا.\u202C',
  contactUs: '\u202Bاتصل بنا\u202C',
  goToBrowserSettings: '\u202Bانتقل إلى إعدادات المتصفح\u202C',
  findSettingBrowser:
    '\u202Bيمكنك العثور على الإعدادات عن طريق النقر على النقاط الثلاث في الزاوية العلوية اليمنى من المتصفح.\u202C',
  selectSiteSettings: '\u202Bاختر إعدادات الموقع\u202C',
  foundAdvanced: '\u202Bيمكن العثور عليها تحت العنوان الفرعي المتقدم.\u202C',
  selectCamera: '\u202Bاختر الكاميرا\u202C',
  selectWebsite: '\u202Bاختر ‘https://scan.laava.id’\u202C',
  changePermission: '\u202Bقم بتغيير الإذن للوصول إلى الكاميرا، إلى السماح.\u202C',
  returnToWebscanner: '\u202Bعد إلى الماسح الضوئي لمسح البصمة.\u202C',
  lookForLaava: '\u202Bابحث عن Laava.\u202C',
  simplyScan: '\u202Bامسح بكل بساطة.\u202C',
  shopWithAssurance: '\u202Bتسوق بثقة.\u202C',
  laavaEnablesBrands:
    '\u202Bتمكن Laava العلامات التجارية من تقديم ضمان لك حول جودة منتجاتها باستخدام تقنية جديدة وآمنة تم تطويرها بالتعاون مع CSIRO.\u202C',
  aboutLaava: '\u202Bحول Laava\u202C',
  knowYouReGetting: '\u202Bتأكد من حصولك على المنتج الأصلي وليس البديل.\u202C',
  laavaIsANewKind:
    '\u202BLaava هو نوع جديد من الباركود الذي يمكن أن يساعد في ضمان أن المنتج الذي تدفع ثمنه هو المنتج الأصلي الحقيقي ويحميك من المنتجات المقلدة ذات الجودة الأقل.\u202C',
  everyFingerprint: '\u202Bكل بصمة فريدة تمامًا ولا تشبه أي بصمة أخرى.\u202C',
  aFingerprintDoes:
    '\u202Bتقوم البصمة بأكثر من مجرد تحديد المنتج أو الدفعة التي تحملها. إنها بصمة فردية وفريدة تمامًا للمنتج المحدد في يدك.\u202C',
  seeBeyondTheLabel: '\u202Bانظر إلى ما وراء الملصق واعرف المزيد عن منتجاتك.\u202C',
  scanningAllowsYou:
    '\u202Bيتيح لك المسح رؤية المزيد من المعلومات غير المدرجة على الملصق. اكتشف معلومات محددة عن هذا العنصر بالذات مثل مكان إنتاجه أو وقت تغليفه.\u202C',
  ourCompany: '\u202Bشركتنا\u202C',
  laavaWasFounded:
    '\u202Bتم تأسيس Laava في أستراليا في عام 2016. تم تطوير المفهوم وحل التكنولوجيا بواسطة خبراء عالميين رائدين في مجال البصريات، سلاسل الكتل، التسويق، ورؤى المستهلك.\u202C',
  needHelpScanning: '\u202Bتحتاج إلى مساعدة في المسح؟\u202C',
  youCanFindDetails:
    '\u202Bيمكنك العثور على تفاصيل حول كيفية المسح، بالإضافة إلى إجابات على المشاكل الشائعة، في دليل المساعدة الخاص بنا.\u202C',
  getHelp: '\u202Bاحصل على المساعدة في المسح\u202C',
  howScan: '\u202Bكيفية المسح\u202C',
  problemsWithTheFingerprint: '\u202Bمشاكل مع البصمة\u202C',
  commonProblems: '\u202Bالمشاكل الشائعة\u202C',
  getResults:
    '\u202Bللحصول على أفضل النتائج، يمكن أن تساعدك هذه النصائح في التغلب على بعض المشاكل الشائعة التي قد تؤثر على جودة المسح.\u202C',
  readyToScan: '\u202Bجاهز لمسح بصمة؟\u202C',
  needMoreHelp: '\u202Bتحتاج إلى مزيد من المساعدة؟\u202C',
  scanFingerprint: '\u202Bامسح بصمتك\u202C',
  bringFingerprint:
    '\u202Bقم بإحضار البصمة إلى التركيز داخل عرض الكاميرا، وابقها في مكانها حتى يتم اكتشاف البصمة. قد يستغرق هذا بضع لحظات حسب سرعة اتصالك.\u202C',
  locateSmartFingerprint: '\u202Bحدد موقع بصمة الإصبع الذكية™ الخاصة بك\u202C',
  fingerprintsCanBeFound: '\u202Bيمكن العثور على بصمات الأصابع على مجموعة متنوعة من المنتجات اليومية.\u202C',
  alignTheFingerprint: '\u202Bقم بمحاذاة البصمة باستخدام الدليل\u202C',
  alignTheGuide: '\u202Bقم بمحاذاة الدليل مع الحلقات الداخلية والخارجية للبصمة.\u202C',
  snapPhoto: '\u202Bالتقط صورة\u202C',
  getYourFingerprint: '\u202Bاجعل بصمتك في التركيز وحافظ على ثبات يدك.\u202C',
  light: '\u202Bالضوء\u202C',
  likeAnyCamera:
    '\u202Bمثل أي كاميرا، تكون أفضل النتائج في الإضاءة الجيدة. تأكد من أن بصمتك مضاءة جيدًا وانتبه للوهج.\u202C',
  keepYourHands: '\u202Bحافظ على ثبات يديك\u202C',
  tryKeepYourHand: '\u202Bحاول أن تبقي يدك والكاميرا ثابتة عند التقاط الصورة، حتى تكون الصورة واضحة.\u202C',
  considerTheAngle: '\u202Bضع في اعتبارك الزاوية\u202C',
  scanYourFingerprint:
    '\u202Bامسح بصمتك في وضع أمامي بالنسبة للكاميرا، واملأ معظم العرض (مع الحفاظ على التركيز).\u202C',
  watchOutForShadow: '\u202Bانتبه للظلال\u202C',
  whenScanning: '\u202Bعند المسح، تأكد من أن هاتفك لا يلقي ظلًا مظلمًا على البصمة.\u202C',
  haveAnyQuestion: '\u202Bلديك أي أسئلة أو تعليقات؟ نحب أن نسمع منك.\u202C',
  email: '\u202Bالبريد الإلكتروني\u202C',
  startingCamera: '\u202Bيتم بدء تشغيل الكاميرا\u202C',
  searching: '\u202Bجارٍ المسح\u202C',
  analysing: '\u202Bجارٍ التحليل\u202C',
  sending: '\u202Bجارٍ الإرسال\u202C',
  sendingForVerification: '\u202Bجارٍ الإرسال للتحقق\u202C',
  mightTakeAFewMoments: '\u202Bقد يستغرق الأمر بضع لحظات\u202C',
  placeCodeWithinFrame: '\u202Bضع البصمة داخل الإطار لمسحها\u202C',
  needHelp: '\u202Bتحتاج إلى مساعدة؟\u202C',
  googlePrivacyPolicy: '\u202Bسياسة خصوصية Google\u202C',
  laavaPrivacy: '\u202Bخصوصية Laava\u202C',
  laavaHasAPrivacy:
    '\u202Bتمتلك Laava \u202C{{manifesto}}\u202B الذي يوضح سياساتنا التي تؤثر عليك وعلى خصوصيتك كمستخدم لبصمات Laava. بالإضافة إلى ذلك، لدينا \u202C{{policy}}\u202B التي تنطبق فقط في الظروف المحدودة التي نحتاج فيها إلى جمع المعلومات الشخصية.\u202C',
  privacyManifesto: '\u202Bبيان الخصوصية\u202C',
  corporatePrivacy: '\u202Bسياسة الخصوصية للشركة\u202C',
  laavaWebscannerTerm: '\u202BLaava – شروط وأحكام الماسح الضوئي على الويب\u202C',
  general: '\u202Bعام\u202C',
  websiteUse: '\u202Bاستخدام الموقع الإلكتروني\u202C',
  intellectualProperty: '\u202Bالملكية الفكرية\u202C',
  limitionOfLiability: '\u202Bتحديد المسؤولية\u202C',
  indemnity: '\u202Bالتعويض\u202C',
  otherWebsites: '\u202Bمواقع إلكترونية أخرى وأطراف ثالثة\u202C',
  entireAgreement: '\u202Bالاتفاقية الكاملة\u202C',
  invalidity: '\u202Bالبطلان وإمكانية التنفيذ\u202C',
  privacy: '\u202Bالخصوصية\u202C',
  applicableLaw: '\u202Bالقانون المعمول به\u202C',
  howToContact: '\u202Bكيفية الاتصال بنا\u202C',
  termsP00:
    '\u202Bشكرًا لدخولك إلى موقع Laava الإلكتروني (الموقع الإلكتروني). الموقع الإلكتروني مملوك ومدار من قبل Laava ID Pty Ltd (A.B.N. 99 617 775 578) (Laava).\u202C',
  termsP01:
    '\u202Bيخضع استخدامك للموقع الإلكتروني لهذه الشروط والأحكام. من خلال الوصول إلى الموقع الإلكتروني واستخدامه، فإنك توافق على الالتزام بهذه الشروط.\u202C',
  termsP02:
    '\u202Bيجوز لـ Laava تغيير جزء أو كل هذه الشروط والأحكام في أي وقت. سيتم نشر أحدث شروط الاستخدام دائمًا على الموقع الإلكتروني. يعتبر استمرار استخدامك للموقع الإلكتروني قبولاً لأي تغييرات تم إجراؤها على هذه الشروط. إذا كنت تعترض على هذه الشروط أو على أي تغييرات تم إجراؤها عليها، فيجب عليك التوقف عن استخدام الموقع الإلكتروني.\u202C',
  termsP03: '\u202Bتم تحديث هذه الشروط آخر مرة في 26 نوفمبر 2018.\u202C',
  termsP1: '\u202Bيمكنك استخدام الموقع الإلكتروني أو أي محتوى فقط لأغراض غير تجارية.\u202C',
  termsP2: '\u202Bالمعلومات الموجودة على الموقع الإلكتروني لأغراض المعلومات العامة فقط.\u202C',
  termsP3:
    '\u202Bيتم توفير الموقع الإلكتروني والمعلومات والروابط والمستندات أو الرسومات ذات الصلة الموجودة على الموقع (مجتمعة، المحتوى) على حالتها. يعتمد أي اعتماد تضعه على المحتوى على مسؤوليتك الخاصة. يجب عليك إجراء تحقيقاتك الخاصة لتحديد ما إذا كان المحتوى مناسبًا لاستخدامك المقصود.\u202C',
  termsP4:
    '\u202Bبينما تبذل Laava كل جهد معقول لضمان أن الموقع الإلكتروني متاح ويعمل بشكل كامل في جميع الأوقات، قد يتم تعليق وصولك إلى الموقع دون إشعار في حالة حدوث فشل في النظام أو الصيانة أو لأي سبب خارج عن سيطرة Laava.\u202C',
  termsP5: '\u202Bيجوز لـ Laava تغيير أو إيقاف، إما مؤقتًا أو دائمًا، ما يلي:\u202C',
  termsP6: '\u202Bالموقع الإلكتروني، أو أي محتوى، ميزة أو خدمة على الموقع الإلكتروني؛ أو\u202C',
  termsP7: '\u202Bالمسار أو موقع الرابط،\u202C',
  termsP8:
    '\u202Bفي أي وقت دون إشعار. تتنصل Laava من أي مسؤولية تتعلق بهذه التغييرات أو الإيقافات أو الانقطاعات المماثلة للموقع الإلكتروني أو أي جزء منه.\u202C',
  termsP9:
    '\u202Bتحتفظ Laava بالحق في إنهاء وصولك إلى الموقع ومنع المزيد من الوصول دون تحذير أو إشعار استجابة لانتهاكاتك لشروط الاستخدام هذه أو انتهاكات أي قوانين سارية، أو نشاط مشبوه.\u202C',
  termsP10: '\u202Bعند استخدامك للموقع الإلكتروني، فإنك توافق على عدم القيام بما يلي:\u202C',
  termsP11: '\u202Bتقديم أي معلومات كاذبة أو مضللة أو خادعة؛\u202C',
  termsP12: '\u202Bاستخدام الموقع بطريقة تنتهك أو قد تنتهك أي قانون أو لائحة؛\u202C',
  termsP13: '\u202Bاستخدام الموقع بطريقة قد تتسبب في انتهاك Laava لأي قانون أو لائحة.\u202C',
  termsP14: '\u202Bاستخدام الموقع بطريقة تعكس سلبًا على Laava أو أي طرف ثالث آخر.\u202C',
  termsP15: '\u202Bجمع المعلومات عن مستخدمي الموقع الآخرين لأي غرض آخر غير ما نصرح به صراحةً.\u202C',
  termsP16: '\u202Bاتخاذ أي إجراء من شأنه، في رأي Laava، أن يفرض عبئًا غير معقول على بنية الموقع التحتية.\u202C',
  termsP17: '\u202Bنشر فيروس كمبيوتر أو أي برنامج أو كود مشابه قد يعطل أو يعطل الموقع.\u202C',
  termsP18:
    '\u202Bاستخدام أي جهاز أو برنامج أو عملية أو وسائل آلية أخرى لاسترجاع، أو نسخ أو فهرسة الموقع أو أي محتوى لأي غرض كان؛ أو\u202C',
  termsP19: '\u202Bاسترجاع أو نسخ أو فهرسة الموقع أو أي محتوى لغرض إنشاء قاعدة بيانات لمعلومات العقارات.\u202C',
  termsP20:
    '\u202Bالموقع الإلكتروني وجميع حقوق الملكية الفكرية في المحتوى الموجود على الموقع، بما في ذلك (على سبيل المثال لا الحصر) أي نصوص أو رسوم بيانية أو صور فوتوغرافية أو شعارات أو علامات تجارية مملوكة أو مرخصة لـ Laava، ما لم يُذكر خلاف ذلك.\u202C',
  termsP21:
    '\u202Bإلا عند الضرورة لعرض المحتوى على الموقع الإلكتروني، أو حيثما يسمح به القانون، أو حيث تحصل على إذن خطي صريح من Laava، لا يجوز لك إعادة إنتاج أو إعادة تجميع أو فك تجميع أو إنشاء أعمال مشتقة أو أداء أو تعديل أو تكييف أو نشر أو تخزين أو استخدام أو إرسال إلكترونيًا أو توزيع أو استغلال الموقع الإلكتروني أو المحتوى بأي وسيلة كانت لأي غرض غير الاستخدام الشخصي غير التجاري الخاص بك.\u202C',
  termsP22: '\u202Bاستخدامك للموقع يتم على مسؤوليتك الخاصة.\u202C',
  termsP23:
    '\u202Bإلى أقصى حد يسمح به القانون، تتنصل Laava من جميع التصريحات والضمانات سواء كانت صريحة أو ضمنية، بما في ذلك، على سبيل المثال لا الحصر:\u202C',
  termsP24: '\u202Bالكمال أو الدقة أو الموثوقية أو الملاءمة أو التوافر فيما يتعلق بالموقع الإلكتروني أو المحتوى؛\u202C',
  termsP25: '\u202Bأن الموقع الإلكتروني والمحتوى سيكونان خاليين من الأخطاء، أو أنه سيتم تصحيح العيوب؛\u202C',
  termsP26: '\u202Bأن وصولك إلى الموقع الإلكتروني سيكون دون انقطاع، آمن أو خالٍ من الأخطاء؛\u202C',
  termsP27: '\u202Bأن الموقع الإلكتروني خالٍ من الفيروسات أو أي مكونات ضارة أخرى؛ و\u202C',
  termsP28: '\u202Bجودة أو دقة أو ملاءمة أي مواقع إلكترونية مرتبطة أو مواد من طرف ثالث.\u202C',
  termsP29:
    '\u202Bإلى أقصى حد يسمح به القانون، لا تتحمل Laava ومديروها وموظفوها ووكلاؤها أي مسؤولية تجاهك عن أي خسارة أو ضرر من أي نوع (بما في ذلك الخسائر التبعية) بأي شكل كان، سواء في العقد أو المسؤولية التقصيرية بما في ذلك الإهمال، أو القانون أو غير ذلك الناشئ فيما يتعلق بالموقع أو المحتوى.\u202C',
  termsP30:
    '\u202Bلا شيء في هذه الشروط يهدف إلى استبعاد أو تقييد أو تعديل الحقوق التي قد تكون لديك بموجب أي قانون (بما في ذلك في أستراليا، قانون المستهلك الأسترالي) والتي لا يمكن استبعادها أو تقييدها أو تعديلها بالاتفاق. إذا كانت هذه الشروط خاضعة لقانون المستهلك الأسترالي، فإن مسؤوليتنا تجاهك عن الإخفاق في الامتثال لضمان المستهلك فيما يتعلق بأي خدمات تقتصر على إعادة تقديم الخدمات أو الدفع مقابل إعادة تقديمها.\u202C',
  termsP31:
    '\u202Bتعوض Laava عن أي مسؤولية تتكبدها Laava عن أي خسائر أو التزامات أو أضرار أو نفقات، بما في ذلك على سبيل المثال لا الحصر، الرسوم القانونية والمحاسبية المعقولة، مهما كان سببها، والتي تتكبدها Laava نتيجة لانتهاكك لهذه الشروط أو لاستخدامك للموقع الإلكتروني.\u202C',
  termsP32:
    '\u202Bقد يحتوي الموقع الإلكتروني على روابط لمواقع إلكترونية تابعة لأطراف ثالثة، إعلانات أو معلومات على تلك المواقع.\u202C',
  termsP33:
    '\u202Bلا تتحكم Laava في طبيعة أو محتوى أو توافر المواقع الإلكترونية التابعة لأطراف ثالثة. لا يعني تضمين أي روابط إلى مواقع إلكترونية أخرى بالضرورة رعاية أو تأييد أو موافقة على تلك المواقع أو الآراء المعبر عنها فيها.\u202C',
  termsP34:
    '\u202Bلا تتحمل Laava أي مسؤولية عن المحتوى الموجود على تلك المواقع. أي استخدام لمواقع الأطراف الثالثة والمعلومات يكون على مسؤوليتك الخاصة.\u202C',
  termsP35:
    '\u202Bتمثل شروط الاستخدام هذه الاتفاقية الكاملة بينك وبين Laava فيما يتعلق باستخدامك والوصول إلى الموقع الإلكتروني واستخدامك والوصول إلى المحتوى الموجود عليه. لا يجوز تضمين أي شرط آخر في هذه الشروط إلا إذا كان مطلوبًا بموجب أي تشريع من الكومنولث الأسترالي أو أي ولاية أو إقليم في أستراليا. يتم استبعاد جميع الشروط الضمنية باستثناء تلك التي لا يمكن استبعادها صراحة.\u202C',
  termsP36:
    '\u202Bإذا كان أي شرط من هذه الشروط غير صالح بموجب قانون أي ولاية قضائية، فإن هذا الشرط يكون قابلًا للتنفيذ في تلك الولاية القضائية إلى الحد الذي لا يكون فيه غير صالح، سواء كان في شروط قابلة للفصل أو لا.\u202C',
  termsP37:
    '\u202Bسيتم التعامل مع جميع المعلومات الشخصية التي تجمعها Laava منك وفقًا لقانون الخصوصية لعام 1988 (Cth) و{{policy}}.\u202C',
  termsP38:
    '\u202Bلمزيد من المعلومات حول كيفية تعامل Laava مع معلوماتك الشخصية عند مسح بصمات Laava، يرجى الرجوع إلى {{manifesto}}.\u202C',
  termsP39:
    '\u202Bتحتفظ Laava بالحق في أي وقت ومن وقت لآخر في تعديل أو إيقاف الماسح الضوئي على الموقع، إما بشكل مؤقت أو دائم أو أي جزء منه لأي سبب. تتنصل Laava من أي مسؤولية نتيجة لأي إيقاف أو انقطاع للماسح الضوئي على الموقع أو أي جزء منه.\u202C',
  termsP40: '\u202Bيمكنك الاتصال بـ Laava عبر:\u202C',
  contact: '\u202Bاتصل بنا\u202C',
  privacyOfficer: '\u202Bمسؤول الخصوصية\u202C',
  address: '\u202Bالعنوان\u202C',
  phone: '\u202Bالهاتف\u202C',
  laavaPrivacyManifesto: '\u202Bبيان خصوصية Laava\u202C',
  privacyManifestoIntroduction: '\u202BLaava – بيان الخصوصية\u202C',
  ourApproach: '\u202Bنهجنا تجاه خصوصيتك\u202C',
  whatInformationScan: '\u202Bما المعلومات التي يتم جمعها عند مسح بصمة Laava؟\u202C',
  whatInformationDetect: '\u202Bما المعلومات التي يتم جمعها عند اكتشاف نشاط مشبوه؟\u202C',
  howDoesLaava: '\u202Bكيف تستخدم Laava ملفات تعريف الارتباط؟\u202C',
  sharingInformation: '\u202Bمشاركة المعلومات\u202C',
  privacyManifestoP1:
    '\u202Bهذه الوثيقة هي بيان الخصوصية الخاص بنا الذي يحدد سياستنا فيما يتعلق بجمع المعلومات عند مسح بصمة Laava الذكية باستخدام متصفح أو ماسح Laava في WeChat.\u202C',
  privacyManifestoP2:
    '\u202Bلدينا \u202C{{link}}\u202B منفصلة تحدد كيفية تعاملنا مع معلوماتك الشخصية في ظروف العمل الأخرى، مثل إذا تقدمت لوظيفة معنا، أو إذا كنت مستثمرًا محتملًا، ممثلًا لشريك، عميل، مورد أو مقدم خدمة لنا، أو إذا اتصلت بمكتب المساعدة لدينا أو قدمت استفسارًا أو سؤالًا.\u202C',
  privacyManifestoP3:
    '\u202Bلا نرغب في جمع أي من معلوماتك الشخصية عند مسح بصمات Laava الذكية. احترام خصوصيتك هو مصدر قلقنا الرئيسي، ولهذا السبب لا نطلب أسماء أو تسجيلات دخول أو أرقام هواتف أو رسائل بريد إلكتروني أو أي معلومات أخرى تحدد هويتك الشخصية.\u202C',
  privacyManifestoP4:
    '\u202Bيعد مسح بصمات Laava الذكية جزءًا أساسيًا من كل ما نقوم به. نريد توفير بيئة آمنة وخاصة وخالية من القلق للجميع عند مسح بصمة Laava الذكية. يعني مسح بصمات Laava الذكية عدم وجود متاعب أو أعين متطفلة.\u202C',
  privacyManifestoP5:
    '\u202Bنعتقد أن العديد من الشركات يمكنها تحسين تعاملها مع خصوصية المستهلك، ونريد أن نكون في المقدمة. مجرد أنه يمكنك جمع البيانات لا يعني أنه يجب عليك فعل ذلك.\u202C',
  privacyManifestoP6:
    '\u202Bعند مسح بصمة Laava الذكية، نقوم بالتقاط صورة لعملية المسح وغيرها من المعلومات المتعلقة بالمسح أو البيانات الوصفية المطلوبة لمصادقة بصمة Laava الذكية وتحديث سجل حالتها. قد تتضمن هذه البيانات الوصفية عنوان URL الذي نشأ منه المسح، موقع المسح (عادةً ما يتم استخلاصه من عنوان IP أو موقع GPS)، ووقت كل مسح. كما نقوم بتعيين وتسجيل معرف ماسح مجهول الهوية مع كل مسح، وقد نستخدم معلومات محدودة عن الجهاز مثل إعدادات المتصفح والكاميرا.\u202C',
  privacyManifestoP7:
    '\u202Bالأهم من ذلك، أن جميع المعلومات التي نجمعها عند مسح بصمة Laava الذكية مجهولة الهوية ولا يمكن استخدامها بسهولة لتحديد هويتك (المعلومات المجهولة). إذا كانت صورة المسح تحتوي على معلومات يمكن استخدامها لتحديد هويتك، فلن نسعى للقيام بذلك.\u202C',
  privacyManifestoP8:
    '\u202Bإذا قمت بمسح بصمة Laava الذكية في برنامجنا المصغر على WeChat، فستكون أيضًا خاضعًا لسياسة الخصوصية الخاصة بـ WeChat.\u202C',
  privacyManifestoP9:
    "\u202Bلضمان أن Laava أداة فعالة ضد التزوير والاحتيال، وللحفاظ على بيئة المسح آمنة والحفاظ على جودة عالية من الخدمة والأداء والأمان، نستخدم مجموعة من الطرق لتحديد ما إذا كان نشاط المسح لبصمة Laava الذكية مشبوهًا. عند اكتشاف نشاط مشبوه، قد نجري تحليلًا شاملًا لنشاط المسح والبيانات الوصفية، وقد ننقل هذه المعلومات إلى عملائنا وشركائنا (يُشار إليهم معًا بـ 'العملاء').\u202C",
  privacyManifestoP10:
    '\u202Bتستخدم Laava ملفات تعريف الارتباط (الكوكيز) على موقعها الإلكتروني. ملف تعريف الارتباط هو جزء صغير من البيانات المخزنة على جهازك لمساعدة المواقع في تذكر أشياء عن تصفحك. تساعدنا ملفات تعريف الارتباط في معرفة أي أجزاء من موقعنا هي الأكثر شعبية، مما يسمح لنا بتحسين تجربتك.\u202C',
  privacyManifestoP11:
    '\u202Bلمساعدة في تحسين موقعنا، قد يتم جمع بعض المعلومات كبيانات إحصائية وقد تشمل عنوان IP، نوع المتصفح، اللغة وأوقات الوصول. تسجل الأدوات المستخدمة على موقعنا المعلومات بطريقة لا تحدد هويتك كفرد. قد يسمح لك متصفحك أو جهازك بحظر أو تقييد استخدام أو إزالة ملفات تعريف الارتباط.\u202C',
  privacyManifestoP12:
    '\u202Bعند مسح بصمات Laava الذكية باستخدام ماسح يستند إلى المتصفح، قد يتم استخدام ملفات تعريف الارتباط، ولكن لن يتم تخزين سجل المسح الخاص بك.\u202C',
  privacyManifestoP13:
    '\u202Bقد يستخدم موقعنا أيضًا أدوات مواقع إلكترونية تابعة لأطراف ثالثة من وقت لآخر، مثل Google Analytics وأدوات الاستطلاعات عبر الإنترنت. لمزيد من المعلومات حول كيفية جمع Google للبيانات ومعالجتها، يرجى الاطلاع على سياسة الخصوصية الخاصة بـ Google والمعلومات الموجودة في \u202C{{link}}\u202B.\u202C',
  privacyManifestoP14:
    '\u202Bيهتم العملاء بطبيعة الحال بتحليل سجلات مسح بصمات Laava الذكية المرتبطة بعناصرهم. نحن نشارك معلومات المسح مع العملاء، عادةً في شكل مجمع. عند مشاركة المعلومات مع العملاء، فإننا نخبرهم فقط بما يحتاجون إلى معرفته، مثل مكان وزمان وعدد مرات مسح بصمة Laava الذكية الخاصة بهم. نظرًا لأننا لا نجمع معلوماتك الشخصية، فلا يمكننا مشاركتها معهم.\u202C',
  privacyManifestoP15:
    '\u202Bنسمح أيضًا للعملاء بالتواصل معك على أساس شخصي عند مسح بصمة Laava الذكية. كجزء من هذا التفاعل، قد يطلب العملاء معلومات شخصية منك. نعتقد أن هذا أمر رائع، ولكن في Laava، يتم مشاركة أي معلومات شخصية دائمًا بطريقة شفافة وعلى أساس الموافقة المسبقة. أنت دائمًا تتحكم وتختار ما تشاركه.\u202C',
  privacyManifestoP16:
    '\u202Bلن ترى Laava أو تجمع معلوماتك الشخصية، بدلاً من ذلك، سيتم جمع أي معلومات شخصية تشاركها والتعامل معها من قبل عملائنا وفقًا لسياسات الخصوصية الخاصة بهم، والتي سيُطلب منك قبولها.\u202C',
  privacyManifestoP17:
    '\u202Bقد تشارك Laava أي معلومات تقدمها عندما تعتقد أن الإفراج عنها مناسب للامتثال للقانون، أو لتسهيل الإجراءات القانونية، أو لحماية حقوق أو ممتلكات أو سلامة Laava أو مستخدمي هذا الموقع والآخرين.\u202C',
  laavaPrivacyPolicy: '\u202Bسياسة الخصوصية الخاصة بـ Laava\u202C',
  privacyPolicyT1: '\u202Bحول سياسة الخصوصية للشركة\u202C',
  privacyPolicyT2: '\u202Bمتى نجمع معلوماتك الشخصية؟\u202C',
  privacyPolicyT3: '\u202Bما هي المعلومات الشخصية التي نجمعها ونتعامل معها؟\u202C',
  privacyPolicyT4: '\u202Bلماذا نجمع معلوماتك الشخصية؟\u202C',
  privacyPolicyT5: '\u202Bلمن قد نكشف معلوماتك الشخصية؟\u202C',
  privacyPolicyT6: '\u202Bماذا يحدث إذا لم نتمكن من جمع معلوماتك الشخصية؟\u202C',
  privacyPolicyT7: '\u202Bزوار موقعنا وملفات تعريف الارتباط\u202C',
  privacyPolicyT8: '\u202Bكيف نحمي معلوماتك الشخصية؟\u202C',
  privacyPolicyT9: '\u202Bالاتصالات التسويقية المباشرة\u202C',
  privacyPolicyT10: '\u202Bكيف يمكنك الوصول إلى معلوماتك الشخصية وتصحيحها؟\u202C',
  privacyPolicyT11: '\u202Bكيف يمكنك تقديم شكوى بشأن الخصوصية؟\u202C',
  privacyPolicyT12: '\u202Bكيفية الاتصال بنا\u202C',
  privacyPolicyP1:
    '\u202Bنعتقد أن العديد من الشركات يمكنها تحسين تعاملها مع خصوصية المستهلك، ونريد أن نكون في المقدمة. مجرد أنه يمكنك جمع البيانات لا يعني أنه يجب عليك القيام بذلك. نحن نقدّر خصوصيتك ونحترمها ونتعامل بجدية مع حماية معلوماتك الشخصية.\u202C',
  privacyPolicyP2:
    '\u202Bلا تتعلق سياسة الخصوصية للشركة هذه بما يحدث لك أو بمعلوماتك الشخصية عند مسح بصمة Laava. سياستنا التي لا تجمع المعلومات الشخصية عند مسح بصمات Laava مغطاة في \u202C{{link}}\u202B منفصلة.\u202C',
  privacyPolicyP3:
    '\u202Bتحدد سياسة الخصوصية للشركة هذه كيف تتعامل Laava ID Pty Ltd (ABN 99617 775 578) (Laava، نحن، خاصتنا) مع معلوماتك الشخصية في الظروف المحدودة التي نضطر فيها إلى جمع المعلومات الشخصية، مثل إذا تقدمت للحصول على وظيفة معنا، أو تسعى للاستثمار أو العمل مع Laava (كممثل لشريك، عميل، مورد أو مقدم خدمة)، أو إذا اتصلت بمكتب المساعدة لدينا أو قدمت استفسارًا.\u202C',
  privacyPolicyP4:
    '\u202Bتحدد المعلومات الشخصية التي نجمعها عنك، متى وكيف نستخدمها. كما تحدد حقوقك في الخصوصية كفرد وكيفية الاتصال بنا إذا كانت لديك أي مخاوف تتعلق بالخصوصية. قد تتغير سياسة الخصوصية هذه من وقت لآخر، لذلك قد ترغب في مراجعتها بانتظام.\u202C',
  privacyPolicyP5:
    '\u202Bكما هو موضح في بيان الخصوصية لدينا، لا نجمع أي معلومات شخصية فيما يتعلق بمسح بصمة Laava.\u202C',
  privacyPolicyP6:
    '\u202Bكما أننا لا نرى أو نجمع معلوماتك الشخصية عندما تختار مشاركة المعلومات الشخصية مع مالكي العلامات التجارية التي تستخدم بصمات Laava (العلامات التجارية). وهذا مشمول أيضًا في بيان الخصوصية لدينا.\u202C',
  privacyPolicyP7:
    '\u202Bومع ذلك، هناك بعض الظروف المحدودة التي نحتاج فيها إلى جمع معلوماتك الشخصية منك مباشرةً، بما في ذلك عندما:\u202C',
  privacyPolicyP8:
    '\u202Bتتواصل معنا عبر الهاتف أو البريد الإلكتروني أو عبر الإنترنت (بما في ذلك من خلال موقعنا الإلكتروني أو مكتب المساعدة لدينا) أو شخصيًا؛\u202C',
  privacyPolicyP9: '\u202Bعند التقدم للحصول على وظيفة معنا؛\u202C',
  privacyPolicyP10: '\u202Bعند التقدم لتصبح مستثمرًا؛ أو\u202C',
  privacyPolicyP11: '\u202Bعند التفاعل معنا كعميل محتمل أو حالي، أو كشريك، أو كمورد أو مقدم خدمة.\u202C',
  privacyPolicyP12:
    '\u202Bالمعلومات الشخصية هي أي معلومات أو رأي (سواء كان صحيحًا أم لا، وسواء تم تسجيله في شكل مادي أم لا) عنك كفرد معروف، أو كفرد يمكن التعرف عليه بشكل معقول. قد تشمل أنواع المعلومات الشخصية التي نجمعها ونتعامل معها:\u202C',
  privacyPolicyP13:
    '\u202Bمعلومات الاتصال، مثل اسمك وعنوانك ورقم هاتفك والبريد الإلكتروني و/أو عنوان IP الخاص بك؛\u202C',
  privacyPolicyP14:
    '\u202Bمعلومات حول دورك داخل مؤسستك، إذا كنت شريكًا أو عميلًا أو موردًا أو مقدم خدمة حاليًا أو محتملاً لنا؛\u202C',
  privacyPolicyP15:
    '\u202Bالتفاصيل المالية وغيرها من التفاصيل ذات الصلة التي تشاركها معنا إذا تقدمت لتصبح مستثمرًا؛\u202C',
  privacyPolicyP16:
    '\u202Bإذا تقدمت للحصول على وظيفة معنا، فقد نجمع معلومات حول مؤهلاتك وخبراتك ومهاراتك وسماتك، والتحقق من الخلفية وسجل التوظيف؛ و\u202C',
  privacyPolicyP17: '\u202Bمعلومات أخرى تقدمها لنا عبر مكتب المساعدة لدينا (أو في أي استفسار ترسله إلينا).\u202C',
  privacyPolicyP18:
    '\u202Bنحن نجمع ونحتفظ ونستخدم ونكشف المعلومات الشخصية بشكل أساسي للرد على الاستفسارات، للنظر في طلبك للحصول على وظيفة معنا، أو للنظر فيك كمستثمر، أو عميل، أو مورد أو شريك لنا. قد نجمع أيضًا معلوماتك الشخصية لأسباب ذات صلة، بما في ذلك:\u202C',
  privacyPolicyP19:
    '\u202Bالامتثال لالتزاماتنا القانونية، ومساعدة الوكالات الحكومية وإنفاذ القانون أو الهيئات التنظيمية؛\u202C',
  privacyPolicyP20: '\u202Bأغراض العمل الداخلية، مثل الفوترة وإدارة الحسابات؛\u202C',
  privacyPolicyP21:
    '\u202Bإدارة علاقتنا معك إذا كنت أنت أو مؤسستك شريكًا أو عميلًا أو موردًا أو مقدم خدمة لنا؛ و\u202C',
  privacyPolicyP22: '\u202Bتحسين خدماتنا أو موقعنا الإلكتروني.\u202C',
  privacyPolicyP23: '\u202Bقد نكشف معلوماتك الشخصية للأغراض الموضحة في سياسة الخصوصية هذه إلى:\u202C',
  privacyPolicyP24:
    '\u202Bموظفينا أو المقاولين أو مقدمي الخدمات (مثل مزودي تكنولوجيا المعلومات، والمحامين، ومستشاري التسويق) حيثما كان ذلك ضروريًا لأداء عملهم لنا؛\u202C',
  privacyPolicyP25:
    '\u202Bشركائنا التجاريين الحاليين أو المحتملين، أو الشركات التابعة، أو المستثمرين، أو الموردين أو مقدمي الخدمات من الأطراف الثالثة؛\u202C',
  privacyPolicyP26: '\u202Bأي شخص تُنقل إليه أصولنا أو أعمالنا (أو أي جزء منها)؛\u202C',
  privacyPolicyP27: '\u202Bطرف ثالث محدد حيث وافقت على الكشف عن المعلومات لهذا الطرف الثالث؛ أو\u202C',
  privacyPolicyP28:
    '\u202Bأشخاص آخرين، بما في ذلك الوكالات الحكومية، والهيئات التنظيمية ووكالات إنفاذ القانون، أو كما هو مطلوب أو مرخص أو مسموح به بموجب القانون.\u202C',
  privacyPolicyP29: '\u202Bإذا لم نتمكن من جمع معلوماتك الشخصية، فقد يحدث بعض من التالي:\u202C',
  privacyPolicyP30: '\u202Bقد نتأخر أو نمنع من معالجة طلبك أو استفسارك؛\u202C',
  privacyPolicyP31: '\u202Bقد لا نتمكن من تقديم الخدمات لك بنفس المستوى أو على الإطلاق؛ أو\u202C',
  privacyPolicyP32:
    '\u202Bإذا كنت مستثمرًا حاليًا أو محتملًا، شريكًا، عميلًا، موردًا أو مقدم خدمة، فقد لا نتمكن من الدخول في علاقة عمل معك، أو قد نكون مقيدين في كيفية التعامل معك.\u202C',
  privacyPolicyP33:
    '\u202Bعندما تزور موقعنا الإلكتروني (www.laava.id)، نستخدم ملفات تعريف الارتباط لجمع المعلومات حول زيارتك. ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم وضعها على جهاز الكمبيوتر أو الجهاز المحمول لجمع معلومات تسجيل الدخول على الإنترنت ومعلومات سلوك الزوار.\u202C',
  privacyPolicyP34:
    '\u202Bعندما تزور موقعنا الإلكتروني، قد نقوم تلقائيًا بجمع المعلومات منك باستخدام ملفات تعريف الارتباط أو تقنيات مماثلة. تساعدنا هذه المعلومات في تحسين موقعنا الإلكتروني وتقديم خدمة أفضل وأكثر تخصيصًا.\u202C',
  privacyPolicyP35:
    '\u202Bنحن نتعامل بجدية مع حماية معلوماتك الشخصية وقد قمنا بتنفيذ تدابير تقنية وتنظيمية وإدارية مناسبة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو استخدامها أو الكشف عنها.\u202C',
  privacyPolicyP36:
    '\u202Bنحن نتخذ خطوات معقولة لضمان أن معلوماتك الشخصية دقيقة وكاملة ومحدثة، ولحمايتها من إساءة الاستخدام أو التداخل أو الفقد أو الوصول غير المصرح به أو التعديل أو الكشف.\u202C',
  privacyPolicyP37:
    '\u202Bقد نرسل اتصالات تسويقية مباشرة إليك عبر البريد الإلكتروني، الرسائل النصية القصيرة أو خدمة رقمية أخرى (مثل الماسح الضوئي في WeChat). إذا كنت لا ترغب في تلقي أي من هذه الاتصالات، يمكنك إلغاء الاشتراك باستخدام تفاصيل الاتصال المقدمة أدناه أو من خلال آلية إلغاء الاشتراك في الرسالة.\u202C',
  privacyPolicyP38: '\u202Bنحن لا نكشف معلوماتك الشخصية لأطراف ثالثة لأغراض التسويق المباشر.\u202C',
  privacyPolicyP39:
    '\u202Bيمكنك طلب الوصول إلى وتصحيح المعلومات الشخصية التي نحتفظ بها عنك في أي وقت عن طريق الاتصال بمسؤول الخصوصية لدينا عبر تفاصيل الاتصال الموجودة في أسفل سياسة الخصوصية هذه. في معظم الحالات، يمكننا مساعدتك بسرعة وبشكل غير رسمي مع هذه الطلبات. في حالات أخرى، قد نحتاج إلى التحقق من هويتك وطلب منك تقديم طلبك كتابيًا.\u202C',
  privacyPolicyP40:
    '\u202Bيمكنك الاتصال بمسؤول الخصوصية لدينا باستخدام تفاصيل الاتصال في أسفل سياسة الخصوصية هذه إذا كان لديك أي مخاوف بشأن كيفية تعاملنا مع معلوماتك الشخصية. سنرد لإعلامك بمن سيتولى معالجة مسألتك ومتى يمكنك توقع رد آخر. قد نطلب منك تفاصيل إضافية بشأن مخاوفك، وقد نحتاج إلى التشاور مع أطراف أخرى للتحقيق في مشكلتك ومعالجتها. سنحتفظ بسجلات لطلبك وأي تسوية. إذا لم تكن راضيًا عن الطريقة التي تعاملنا بها مع شكواك، يمكنك الاتصال بمكتب مفوض المعلومات الأسترالي.\u202C',
  privacyPolicyP41:
    '\u202Bإذا كانت لديك أي أسئلة أو شكاوى أو مطالبات تتعلق بماسح الويب أو بشروط وأحكام هذه الوثيقة، يمكنك الاتصال بـ Laava على:\u202C',
  problemsTitle: '\u202Bمشاكل مع البصمة\u202C',
  problemsIntro:
    '\u202Bإذا ظهرت بصمة Laava الذكية™ تالفة أو كنت تواجه مشكلة في المسح، يمكنك التواصل معنا وسنبذل قصارى جهدنا لمساعدتك في حل المشكلة.\u202C',
  tryScanningAgain: '\u202Bحاول المسح مرة أخرى\u202C',
  emailUs: '\u202Bأرسل لنا بريدًا إلكترونيًا\u202C',
  troubleDetectingFingerprint: '\u202Bنواجه مشكلة في اكتشاف بصمة.\u202C',
  makeSureFingerprint: '\u202Bتأكد من أن البصمة <strong>مضاءة جيدًا</strong> و <strong>في بؤرة التركيز</strong>.\u202C',
  ensureInternet: '\u202Bتأكد من أن <strong>اتصالك بالإنترنت</strong> جيد.\u202C',
  tryAgain: '\u202Bحاول مرة أخرى\u202C',
  logIn: '\u202Bتسجيل الدخول\u202C',
  account: '\u202Bالحساب\u202C',
  emailAddress: '\u202Bعنوان البريد الإلكتروني\u202C',
  password: '\u202Bكلمة المرور\u202C',
  submit: '\u202Bإرسال\u202C',
  thereWasAProblemLoggingIn: '\u202Bحدثت مشكلة في تسجيل الدخول. يرجى المحاولة مرة أخرى.\u202C',
  loggedInAs: '\u202Bتم تسجيل الدخول كـ\u202C',
  logOut: '\u202Bتسجيل الخروج\u202C',
}

export default ar
